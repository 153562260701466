import {
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  UPDATE_CURRENT_PROFILE,
  GET_REQUESTED_DETAILS_REQUEST,
  GET_REQUESTED_DETAILS_SUCCESS,
  GET_REQUESTED_DETAILS_FAILURE,
  GET_TAX_TYPE_REQUEST,
  GET_TAX_TYPE_SUCCESS,
  GET_TAX_TYPE_FAILURE,
  GET_PROFILE_USERNAME_REQUEST,
  GET_PROFILE_USERNAME_SUCCESS,
  GET_PROFILE_USERNAME_FAILURE,
} from './profileTypes';

const initialState = {
  loading: false,
  profiles: [],
  error: '',
  currentProfile: {},
  completeProfile: {},
  completeDetails: {},
  businessDetails: {},
  addressDetails: {},
  taxType: {},
  profileAccountDetails: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROFILE_REQUEST:
    case GET_PROFILES_REQUEST:
    case GET_REQUESTED_DETAILS_REQUEST:
    case GET_TAX_TYPE_REQUEST:
    case GET_PROFILE_USERNAME_REQUEST:
      return { ...state, loading: true };

    case GET_PROFILE_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        profileAccountDetails: action.payload,
      };

    case CREATE_PROFILE_SUCCESS:
      if (state.profiles === undefined) {
        return {
          ...state,
          loading: false,
          error: '',
          profiles: [action.payload],
        };
      }

    case GET_TAX_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        taxType: action.payload,
      };

    case GET_REQUESTED_DETAILS_SUCCESS:
      switch (action.details) {
        case 'businessDetails':
          return {
            ...state,
            loading: false,
            error: '',
            businessDetails: action.payload.businessDetails,
          };
        case 'addressDetails':
          return {
            ...state,
            loading: false,
            error: '',
            addressDetails: action.payload.addressDetails,
          };
        default:
          return {
            ...state,
            loading: false,
            error: '',
            completeDetails: action.payload,
          };
      }

    case CREATE_PROFILE_FAILURE:
    case GET_PROFILES_FAILURE:
    case GET_REQUESTED_DETAILS_FAILURE:
    case GET_TAX_TYPE_FAILURE:
    case GET_PROFILE_USERNAME_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_PROFILES_SUCCESS:
      return { ...state, loading: false, error: '', profiles: action.payload };

    case UPDATE_CURRENT_PROFILE:
      localStorage.setItem('profileId', action.payload.profileId);
      localStorage.setItem('type', action.payload.type);
      localStorage.setItem('businessType', action.payload.businessType);
      localStorage.setItem('name', action.payload.name);
      localStorage.setItem('branch', action.payload.branch);
      localStorage.setItem('businessName', action.payload.businessName);
      localStorage.setItem(
        'businessProfileId',
        action.payload.businessProfileId
      );
      localStorage.setItem('designation', action.payload.designation);
      return { ...state, currentProfile: action.payload };

    default:
      return { ...state };
  }
};

export default profileReducer;
