import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import Site from '../components/site/Site';
import EventsComponent from '../components/events/events';
import EventsSettingComponent from '../components/events/eventsSetting';


export default class FestivalEvents extends Component {
  render() {
    return (
      <RegularLayout>
      <Site>
        <br />
        <EventsSettingComponent/>
        {/* <EventsComponent /> */}
      </Site>
      </RegularLayout>
    );
  }
}
