import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import Config from '../../config/build';
import API from '../../constants/apis';

const ServicesStats = () => {
  const tableRef = useRef();

  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedServiceName, setEditedServiceName] = useState('');
  const [editedCoordinator, setEditedCoordinator] = useState('');
  const [editedCoordinatorContact, setEditedCoordinatorContact] = useState('');
  const [editedSpocContact, setEditedSpocContact] = useState('');
  const [editedSpocName, setEditedSpocName] = useState('');

  const navigate = useNavigate();
  const params = useParams();
  const eventId = params.eventId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          API.ServicesByEventId,
          { eventId: eventId }
        );
        setServicesData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  const downloadCSV = () => {
    const csvRows = [];
    const headers = ['Service Name', 'Badge Collected', 'Badge Returned', 'Yet to Collect', 'Total', '% Render Service'];
    csvRows.push(headers.join(','));

    let grandTotalCollected = 0;
    let grandTotalReturned = 0;
    let grandTotalNotCollected = 0;
    let grandTotalTotal = 0;

    servicesData.forEach(item => {
      const collected = item.badgeCollected;
      const returned = item.badgeReturned;
      const notCollected = item.total - collected - returned;
      const total = item.total;
      const percentage = (((collected + returned) / total) * 100).toFixed(0);

      grandTotalCollected += collected;
      grandTotalReturned += returned;
      grandTotalNotCollected += notCollected;
      grandTotalTotal += total;

      const row = [
        item.servicesDAO.name,
        collected,
        returned,
        notCollected,
        total,
        percentage + '%'
      ];
      csvRows.push(row.join(','));
    });

    const grandTotalPercentage = (((grandTotalCollected + grandTotalReturned) / grandTotalTotal) * 100).toFixed(0);
    const grandTotalRow = [
      'Grand Total',
      grandTotalCollected,
      grandTotalReturned,
      grandTotalNotCollected,
      grandTotalTotal,
      grandTotalPercentage + '%'
    ];
    csvRows.push(grandTotalRow.join(','));

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'ServicesStats.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <div>Loading...</div>;

  const handleEdit = (ServicesRowData) => {
    setEditableRowId(ServicesRowData.id);
    setEditedServiceName(ServicesRowData.name);
    setEditedCoordinator(ServicesRowData.coordinator?.name);
    setEditedCoordinatorContact(ServicesRowData.coordinator?.phone);
    setEditedSpocContact(ServicesRowData.spoc?.phone);
    setEditedSpocName(ServicesRowData.spoc?.name);
  };

  const handleUpdateClick = async (ServicesRowData) => {
    try {
      const editedData = {
        id: ServicesRowData.id,
        eventId: eventId,
        name: editedServiceName,
        coordinator: {
          name: editedCoordinator,
          phone: editedCoordinatorContact,
        },
        spoc: {
          name: editedSpocName,
          phone: editedSpocContact,
        },
      };
      await axios.post(API.ServicesUpdate, editedData);

      setEditableRowId(null);
      window.alert('Service updated successfully!');
    } catch (error) {
      console.error('Error updating service', error);
      window.alert('Something went wrong!', error);
    }
  };

  const handleCancelClick = () => {
    setEditableRowId(null);
  };

  const handleDelete = (event) => {
    // Implement delete functionality if required
  };

  const handleMoreDetails = (service) => {
    navigate('/vcc/service-volunteers/' + eventId + '/' + service.id, {
      state: { service },
    });
  };

  const generateTableRows = () => {
    let grandTotalCollected = 0;
    let grandTotalReturned = 0;
    let grandTotalNotCollected = 0;
    let grandTotalTotal = 0;
    
    const rows = servicesData.map((item) => {
      grandTotalCollected += item.badgeCollected;
      grandTotalReturned += item.badgeReturned;
      grandTotalNotCollected += item.total - item.badgeCollected - item.badgeReturned;
      grandTotalTotal += item.total;

      return (
        <tr key={item.servicesDAO.id}>
          <td>{item.servicesDAO.name}</td>
          <td>{item.badgeCollected}</td>
          <td>{item.badgeReturned}</td>
          <td>{item.total - item.badgeCollected - item.badgeReturned}</td>
          <td>{item.total}</td>
          <td>{(((item.badgeCollected + item.badgeReturned) / item.total) * 100).toFixed(0)}%</td>
        </tr>
      );
    });

    rows.push(
      <tr key="grandTotal">
        <td style={{ fontWeight: 'bold' }}>Grand Total</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalCollected}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalReturned}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalNotCollected}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalTotal}</td>
        <td style={{ fontWeight: 'bold' }}>
          {(((grandTotalCollected + grandTotalReturned) / grandTotalTotal) * 100).toFixed(0)}%
        </td>
      </tr>
    );

    return rows;
  };

  return (
    <div>
      <h1 className="heading" style={{ textAlign: 'center', color: '#004080' }}>
        Coordinators Stats page
      </h1>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '40vh',
        }}
      >
        <div>
          <Button onClick={downloadCSV}>Download as CSV</Button>
          <Table ref={tableRef} striped bordered hover className="tc">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Service Name
                </th>
                <th
                  style={{ backgroundColor: '#008cba', color: 'white' }}
                  colSpan="5"
                >
                  Badges
                </th>
              </tr>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}></th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Collected
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Returned
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Yet to Collect
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Total
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  % Render Service
                </th>
              </tr>
            </thead>
            <tbody>{generateTableRows()}</tbody>
          </Table>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ServicesStats;
