import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import profileReducer from './profile/profileReducer';
// import accountReducer from './modules/account/accountReducer';
import accessManagementReducer from './modules/accessManagement/accessManagementReducer';
// import studentReducer from './modules/school/student/studentsReducer';
// import noticeReducer from './modules/school/notice/noticeReducer';
// import employeeReducer from './modules/employee/employeeReducer';
// import academicsReducers from './modules/school/academics/academicsReducers';
// import classesReducers from './modules/school/academics/classes/classesReducers';
// import examReducer from './modules/school/academics/examination/exams/examinationReducer';
// import subjectsReducer from './modules/school/academics/classes/subjects/subjectsReducer';
// import adReducer from './modules/advertisement/ads/adReducer';
// import adMetricsReducer from './modules/advertisement/metrics/metricsReducer';
// import schoolDashboardReducer from './modules/school/dashboard/dashboardReducer';
// import fixedFeeReducers from './modules/school/academics/classes/Fees/fixed/fixedFeesReducers';
// import visitingCardReducer from './modules/visitingcard/visitingCardReducer';
// import studentAttendanceReducer from './modules/school/academics/classes/Attendance/AttendanceReducer';
// import billingReducer from './modules/billing/billingReducer';
// import resultsReducer from './modules/school/academics/examination/results/resultsReducer';
// import individualFeeReducers from './modules/school/academics/classes/Fees/individual/individualFeesReducers';
// import feePaymentsReducer from './modules/school/academics/classes/Fees/payments/feePaymentReducers';
// import screensReducer from './modules/movie-theater/screens/screenReducer';
// import itemsReducer from './modules/restaurant/items/itemRedux';
// import itemCategoriesReducer from './modules/restaurant/categories/categoryRedux';
// import receiptsReducer from './modules/receipts/receiptsReducer';
// import expensesReducer from './modules/expenses/expensesReducer';

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  // employee: employeeReducer,
  accessManagement: accessManagementReducer,
  // account: accountReducer,
  // student: studentReducer,
  // notice: noticeReducer,
  // academics: academicsReducers,
  // classes: classesReducers,
  // exams: examReducer,
  // subjects: subjectsReducer,
  // ads: adReducer,
  // adMetrics: adMetricsReducer,
  // schoolDashboard: schoolDashboardReducer,
  // studentAttendance: studentAttendanceReducer,
  // fixedFeeComponents: fixedFeeReducers,
  // individualFee: individualFeeReducers,
  // feePayments: feePaymentsReducer,
  // visitingCard: visitingCardReducer,
  // subjectResults: resultsReducer,
  // billing: billingReducer,
  // receipts: receiptsReducer,
  // expenses: expensesReducer,
  // screensReducer: screensReducer,
  // // Restaurant Reducers
  // foodItems: itemsReducer,
  // itemCategories: itemCategoriesReducer,
});

export default rootReducer;
