// Action types for login requests
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

// Action types for logout requests
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

// Action types for register requests
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

// Action types for register requests
export const VERIFY_AND_REGISTER_USER_REQUEST =
  'VERIFY_AND_REGISTER_USER_REQUEST';
export const VERIFY_AND_REGISTER_USER_SUCCESS =
  'VERIFY_AND_REGISTER_USER_SUCCESS';
export const VERIFY_AND_REGISTER_USER_FAILURE =
  'VERIFY_AND_REGISTER_USER_FAILURE';

// Action types for register requests
export const REGISTER_OTP_REQUEST = 'REGISTER_OTP_REQUEST';
export const REGISTER_OTP_SUCCESS = 'REGISTER_OTP_SUCCESS';
export const REGISTER_OTP_FAILURE = 'REGISTER_OTP_FAILURE';

// Action types for LOGOUT_FROM_ALL_DEVICES
export const LOGOUT_FROM_ALL_DEVICES_REQUEST =
  'LOGOUT_FROM_ALL_DEVICES_REQUEST';
export const LOGOUT_FROM_ALL_DEVICES_SUCCESS =
  'LOGOUT_FROM_ALL_DEVICES_SUCCESS';
export const LOGOUT_FROM_ALL_DEVICES_FAILURE =
  'LOGOUT_FROM_ALL_DEVICES_FAILURE';

// Action types for RESET_PASSWORD requests
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// Action types for Request Forgot Password OTP
export const FORGOT_PASSWORD_OTP_REQUEST = 'FORGOT_PASSWORD_OTP_REQUEST';
export const FORGOT_PASSWORD_OTP_SUCCESS = 'FORGOT_PASSWORD_OTP_SUCCESS';
export const FORGOT_PASSWORD_OTP_FAILURE = 'FORGOT_PASSWORD_OTP_FAILURE';

// Action types for Reset Forgot Password
export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const RESTORE_SESSION = 'RESTORE_SESSION';
