import React, { useState, useEffect } from "react";
import axios from "axios";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import API from '../../constants/apis';

const CenterPlacesComponent = () => {
  const [CenterPlacesData, setCenterPlacesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableplacesId, setEditableplacesId] = useState(null);
  const [editedplaces, setEditedplaces] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const centerPlaceId = params.accommodationCenterId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          // "http://localhost:3000/accommodationInfo/byCenterId", 
          API.AccommodationsInfoByCenterId,
          {
          centerId: centerPlaceId,
        });
        setCenterPlacesData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [centerPlaceId]);

  const handleEdit = (places) => {
    setEditableplacesId(places.id);
    setEditedplaces({ ...places });
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(
        // "http://localhost:3000/accommodationInfo/update",
        API.AccommodationsInfoByUpdate,
        {
          id: editedplaces.id,
          centerId: centerPlaceId,
          place: editedplaces.place,
          bedsCount: editedplaces.bedsCount,
        }
      );

      setCenterPlacesData((prevData) =>
        prevData.map((places) =>
          places.id === editableplacesId ? editedplaces : places
        )
      );

      setEditableplacesId(null);
      setEditedplaces({});
      window.alert("Place updated successfully!");
    } catch (error) {
      console.error("Error updating places", error);
    }
  };

  const handleDelete = async (places) => {
    try {
      // Add logic to delete places using axios
      // Example: await axios.post("http://localhost:3000/accommodationInfo/delete", { id: places.id });

      // Update state after successful deletion
      setCenterPlacesData((prevData) =>
        prevData.filter((acc) => acc.id !== places.id)
      );

      window.alert("Center deleted successfully!");
    } catch (error) {
      console.error("Error deleting places", error);
    }
  };

  const handleAdd = () => {
    const newPlace = {
      place: "",
      bedsCount: "",
    };

    setCenterPlacesData((prevEvents) => [...prevEvents, newPlace]);
    setEditableplacesId(newPlace.id);
    setShowAddForm(false);
  };

  const handleAddCancel = () => {
    setEditableplacesId(null);
  };

  const handleManagePlaces = (centerPlaceId) => {
    navigate("/vcc/accommodation/place/" + centerPlaceId);
  };
  
  const handleAddplaces = async () => {
    try {
      const createData = {
        centerId: centerPlaceId,
        place: editedplaces.place,
        bedsCount: editedplaces.bedsCount,
      };
      
      const response = await axios.post(
        // "http://localhost:3000/accommodationInfo/create",
        API.AccommodationsInfoByCreate,
        createData,
      );

      setCenterPlacesData((prevData) => [...prevData, response.data]);
      setShowAddForm(false);
      setEditedplaces({});
      window.alert("place added successfully!");
    } catch (error) {
      console.error("Error adding places", error);
    }
  };

  const actionsColumn = {
    label: "Actions",
    name: "actions",
    options: {
      customBodyRenderLite: (dataIndex, rowIndex) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {editableplacesId === CenterPlacesData[rowIndex].id && !showAddForm ? (
            <>
              <button
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                style={{
                  backgroundColor: "#B22222",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={handleAddCancel}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                style={{
                  backgroundColor: "#008CBA",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => handleEdit(CenterPlacesData[rowIndex])}
              >
                <EditOutlined />
              </button>
              <button
                style={{
                  backgroundColor: "#FF4500",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() => handleDelete(CenterPlacesData[rowIndex])}
              >
                <DeleteOutlined />
              </button>
              <button
                style={{
                  backgroundColor: "#808080",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={() =>
                  handleManagePlaces(CenterPlacesData[rowIndex].id)
                }
              >
                MoreDetails
              </button>
            </>
          )}
        </div>
      ),
    },
  };

  const columns = ["Place", "Beds Count", actionsColumn];

  const data = CenterPlacesData.map((places) => [
    editableplacesId === places.id && !showAddForm ? (
      <input
        type="text"
        value={editedplaces.place || ""}
        onChange={(e) =>
          setEditedplaces({ ...editedplaces, place: e.target.value })
        }
      />
    ) : (
      places.place
    ),
    editableplacesId === places.id && !showAddForm ? (
      <input
        type="number"
        value={editedplaces.bedsCount || 0}
        onChange={(e) =>
          setEditedplaces({
            ...editedplaces,
            bedsCount: parseInt(e.target.value) || 0,
          })
        }
      />
    ) : (
      places.bedsCount
    ),
    editableplacesId === places.id && !showAddForm ? (
      <>
        <button style={{ cursor: "pointer" }} onClick={handleUpdate}>
          Update
        </button>
        <button style={{ cursor: "pointer" }} onClick={handleAddCancel}>
          Cancel
        </button>
      </>
    ) : (
      <>
        <button style={{ cursor: "pointer" }} onClick={() => handleEdit(places)}>
          <EditOutlined />
        </button>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => handleDelete(places)}
        >
          <DeleteOutlined />
        </button>

        <button
          style={{ cursor: "pointer" }}
          onClick={() => handleManagePlaces(places.id)}
        >
          MoreDetails
        </button>
      </>
    ),
  ]);

  if (showAddForm) {
    // Add form for adding new places
    data.unshift([
      <input
        type="text"
        placeholder="places Name"
        value={editedplaces.place || ""}
        onChange={(e) =>
          setEditedplaces({ ...editedplaces, place: e.target.value })
        }
      />,
      <input
        type="number"
        placeholder="Name of beds"
        value={editedplaces.bedsCount || ""}
        onChange={(e) =>
          setEditedplaces({
            ...editedplaces,
            bedsCount: parseInt(e.target.value) || "",
          })
        }
      />,
      <>
        <button style={{ cursor: "pointer" }} onClick={handleAddplaces}>
          Add
        </button>
        <button style={{ cursor: "pointer" }} onClick={handleAddCancel}>
          Cancel
        </button>
      </>,
    ]);
  }
  
  

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
  };

  return (
    <div>
      <h1 className="heading" style={{ textAlign: "center", color: "#004080" }}>
        CenterPlaces Page
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "40vh",
        }}
      >
        <div>
          <button
            style={{
              backgroundColor: "#004080",
              color: "#fff",
              padding: "10px",
              margin: "10px 0",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleAdd}
          >
            Add Place
          </button>
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default CenterPlacesComponent;
