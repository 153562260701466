import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import HomePage_ from '../components/home/home';
import AdminHomePage from '../components/home/adminHomePage';
import Site from '../components/site/Site';

export default class HomePage extends Component {
  render() {
    return (
    <RegularLayout>
      <Site>
       
        <AdminHomePage />
      </Site>
      </RegularLayout>
    );
  }
}
