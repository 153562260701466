import React, { useState } from 'react';
import axios from 'axios';
import './AddEvent.css';
import { useNavigate } from 'react-router-dom';
import Config from '../../config/build';

const AddEventComponent = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    place: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const handleFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post(Config.baseurl + '/events/create', formData)
      .then((response) => {
        // console.log(response.data);
        // Handle the response here. This might include clearing the form data, showing a success message, etc.
        window.alert('Event added successfully!');
        navigate('/vcc/events/');
      })
      .catch((error) => {
        console.error(error);
        // Handle the error here. This might include showing an error message to the user.
        window.alert('Oops! Something went wrong. Please try again.');
      });
  };

  return (
    
    <div className="add-event-container" style={{paddingBottom:'3vh'}}>
      <div className="add-event-box">
        <h4 className="heading">Add New Event</h4>
        <form onSubmit={handleFormSubmit}>
        <div className="row">
            <div className="col-sm-6">
          <div className="form-group">
            <label>Event Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          </div>
          <div className="col-sm-6">
          <div className="form-group">
            <label>Event Place</label>
            <input
              type="text"
              name="place"
              value={formData.place}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
          <div className="form-group">
            <label>Event Start Date</label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          </div>
          <div className="col-sm-6">
          <div className="form-group">
            <label>Event End Date</label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          </div>
          </div>

         

          <div className="row">
            <div className="col-sm-3">
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
            <div className="col-sm-3">
              <button className="btn btn-secondary" onClick={props.handleAddCancel}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
 

export default AddEventComponent;
