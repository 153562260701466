import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import AddEventComponent from '../components/events/AddEvent';
import Site from '../components/site/Site';

export default class AddEventPage extends Component {
  render() {
    return (
      <RegularLayout>
      <Site>
        <br />
        <AddEventComponent />
      </Site>
      </RegularLayout>
    );
  }
}
