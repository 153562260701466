import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';

const HeaderComponent = (props) => {
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          {/* <Navbar.Brand href="/">Home</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              

            </Nav>
          </Navbar.Collapse>
          {/* <Nav.Link href="/login" style={{ marginRight: '10px' }}>
            <i>Login</i>
          </Nav.Link>
          <Nav.Link href="/signup">
            <i>Signup</i>
          </Nav.Link> */}
        </Container>
      </Navbar>
    </div>
  );
};

export default HeaderComponent;
