import React, { Fragment } from 'react';
import SideNav from '../sidenav/Sidenav';
import './site.css';

const Site = (props) => {
  return (
    <Fragment>
      <SideNav/>
        {props.children}
    </Fragment>
  );
};

export default Site;
