import React, { useState, useEffect } from "react";
import axios from "axios";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import AddEventComponent from "./AddEvent";
import API from "../../constants/apis";
// import "mui-datatables/dist/react-mui-datatables.css";

const EventsComponent = () => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editableEventId, setEditableEventId] = useState(null);
  const [editedEventData, setEditedEventData] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const eventId = params.eventId;

  const handleAddClick = () => {
    // Create a new empty event
    const newEvent = {
      name: "",
      place: "",
      startDate: "",
      endDate: "",
    };

    // Add the new event to the eventsData state
    setEventsData((prevEvents) => [...prevEvents, newEvent]);

    // Set the editableEventId to the new event's ID
    setEditableEventId(newEvent.id);

    // Show the Add Event form
    setShowAddForm(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // "http://localhost:3000/events/")
          API.Events
        );
        setEventsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleDelete = (event) => {
    console.log("Delete event:", event);
  };
  const ManageService = (eventId) => {
    navigate("/vcc/events/" + eventId);
  };

  const handleAddCancel = () => {
    // Remove the temporary event when canceling
    // setEventsData((prevEvents) =>
    //   prevEvents.filter((event) => event.id !== editableEventId)
    // );
    // setShowAddForm(false);
    setEditableEventId(null);
    // setEditedEventData({});
  };

  const handleEditSymbol = (event) => {
    setEditableEventId(event.id);
    setEditedEventData({ ...event });
  };

  // const handleCancelEdit = () => {
  //   // Remove the temporary event when canceling editing
  //   setEventsData((prevEvents) =>
  //     prevEvents.filter((event) => event.id !== editableEventId)
  //   );
  //   setEditableEventId(null);
  //   setEditedEventData({});
  // };

  const handleCancelEdit = () => {
    setEditableEventId({});
    setEditedEventData({});
  };

  const handleUpdateEvent = async () => {
    try {
      const response = await axios.post(
        // "http://localhost:3000/events/update",
        API.EventsUpdate,
        {
          id: editableEventId,
          name: editedEventData.name,
          startDate: editedEventData.startDate,
          endDate: editedEventData.endDate,
          place: editedEventData.place,
          // Add other fields if needed
        }
      );

      // Update the eventsData state with the updated event
      setEventsData((prevEvents) =>
        prevEvents.map((event) =>
          event.id === editableEventId ? editedEventData : event
        )
      );

      setEditableEventId(null);
      setEditedEventData({});
      setShowAddForm(false);
      window.alert("Event updated successfully!");
      console.log("Event updated successfully", response.data);
    } catch (error) {
      console.error("Error updating event", error);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };


  const actionsColumn = {
    label: 'Actions',
    name: 'actions',
    options: {
      customBodyRenderLite: (dataIndex, rowIndex) => (
        <div
          style={{
            display: 'flex',
            gap: '5px',
          }}
        >
          {editableEventId === eventsData[rowIndex].id && !showAddForm ? (
            <>
              <button
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={handleUpdateEvent}
              >
                Update
              </button>
              <button
                style={{
                  backgroundColor: '#B22222',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={handleAddCancel}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                style={{
                  backgroundColor: '#008CBA',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={() => handleEditSymbol(eventsData[rowIndex])}
              >
                <EditOutlined />
              </button>
              <button
                style={{
                  backgroundColor: '#FF4500',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={() => handleDelete(eventsData[rowIndex])}
              >
                <DeleteOutlined />
              </button>
              <button
                style={{
                  backgroundColor: '#808080',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={() => ManageService(eventsData[rowIndex].id)}
              >
                Manage Centers
              </button>
            </>
          )}
        </div>
      ),
    },
  };
  const columns = [
    "Event Name",
    "Event Place",
    "Event Start Date",
    "Event End Date",
    // "Actions",
    actionsColumn,
  ];

  const data = eventsData.map((event) => [
    editableEventId === event.id ? (
      <input
        type="text"
        value={editedEventData.name || ""}
        onChange={(e) =>
          setEditedEventData({ ...editedEventData, name: e.target.value })
        }
      />
    ) : (
      event.name
    ),
    editableEventId === event.id ? (
      <input
        type="text"
        value={editedEventData.place || ""}
        onChange={(e) =>
          setEditedEventData({ ...editedEventData, place: e.target.value })
        }
      />
    ) : (
      event.place
    ),
    editableEventId === event.id ? (
      <input
        type="date"
        value={editedEventData.startDate || ""}
        onChange={(e) =>
          setEditedEventData({
            ...editedEventData,
            startDate: e.target.value,
          })
        }
      />
    ) : (
      formatDate(event.startDate)
    ),
    editableEventId === event.id ? (
      <input
        type="date"
        value={editedEventData.endDate || ""}
        onChange={(e) =>
          setEditedEventData({
            ...editedEventData,
            endDate: e.target.value,
          })
        }
      />
    ) : (
      formatDate(event.endDate)
    ),
    editableEventId === event.id ? (
      <>
        <button style={{ cursor: "pointer" }} onClick={handleUpdateEvent}>
          Update
        </button>
        <button style={{ cursor: "pointer" }} onClick={handleCancelEdit}>
          Cancel
        </button>
      </>
    ) : (
      <>
        <button style={{ cursor: "pointer" }} onClick={() => handleEditSymbol(event)}>
          <EditOutlined />
        </button>
        <button style={{ cursor: "pointer" }} onClick={() => handleDelete(event)}>
          <DeleteOutlined />
        </button>
        <button style={{ cursor: "pointer" }} onClick={() => ManageService(event.id)}>
          Manage Service
        </button>
      </>
    ),
  ]);

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    setTableProps: () => ({
      style: {
        backgroundColor: "#f4f4f4",
      },
    }),
    setTableHeader: (columns) => ({
      style: {
        backgroundColor: "#004080",
        color: "#fff",
      },
    }),
  };

  return (
    <div style={{ padding: "20px", background: "#f4f4f4", minHeight: "100vh" }}>
      <h1 className="heading" style={{ textAlign: "center", color: "#333" }}>
        Events Setting Page
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "40vh",
        }}
      >
        <div>
          {!showAddForm && (
            <button
              style={{
                backgroundColor: "#004080",
                color: "#fff",
                padding: "10px",
                margin: "10px 0",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={handleAddClick}
            >
              Add Event
            </button>
          )}
          {showAddForm && (
            <AddEventComponent
              handleAddCancel={handleAddCancel}
              // Add other props as needed
            />
          )}
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
          <h1>End of List</h1>
        </div>
      </div>
    </div>
  );
};

export default EventsComponent;
