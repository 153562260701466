import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import EventsSettingComponent from '../components/events/eventsSetting';
import Site from '../components/site/Site';

export default class FestivalEventsSetting extends Component {
  render() {
    return (
      <RegularLayout>
      <Site>
        <br />
        <EventsSettingComponent />
      </Site>
      </RegularLayout>
    );
  }
}
