import React from 'react';
import bgImg from './foot-bg.jpeg';

const HomeFooter = () => {
  return <></>;
};

const styles = {
  footer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
    position: 'relative',
    width: '100vw',
    height: 'auto', // Adjust the height as needed
    background: `url(${bgImg})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  address: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  email: {
    fontSize: '16px',
    marginBottom: '5px',
  },
  phone: {
    fontSize: '16px',
  },
};

export default HomeFooter;
