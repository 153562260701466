import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import AddServiceComponent from '../components/services/AddService';
import Site from '../components/site/Site';

export default class AddServicePage extends Component {
  render() {
    return (
      <RegularLayout>
      <Site>
        <br />
        <AddServiceComponent />
      </Site>
      </RegularLayout>
    );
  }
}
