import React, { useState, useEffect } from "react";
import axios from "axios";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import API from '../../constants/apis';
import 'bootstrap/dist/css/bootstrap.min.css';

const AccommodationsCentersComponent = () => {
  const [accommodationsInfoData, setAccommodationsInfoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableAccommodationId, setEditableAccommodationId] = useState(null);
  const [editedAccommodation, setEditedAccommodation] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const accommodationCenterId = params.accommodationCenterId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API.AccommodationsInfo);
        setAccommodationsInfoData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accommodationCenterId]);

  const handleEdit = (accommodation) => {
    setEditableAccommodationId(accommodation.id);
    setEditedAccommodation({ ...accommodation });
  };

  const handleUpdate = async () => {
    try {
      await axios.post(
        API.AccommodationsCentersUpdate,
        {
          id: editableAccommodationId,
          name: editedAccommodation.name,
          place: editedAccommodation.place,
          // Add other fields if needed
        }
      );

      setAccommodationsInfoData((prevData) =>
        prevData.map((accommodation) =>
          accommodation.id === editableAccommodationId ? editedAccommodation : accommodation
        )
      );

      setEditableAccommodationId(null);
      setEditedAccommodation({});
      window.alert("Center updated successfully!");
    } catch (error) {
      console.error("Error updating accommodation", error);
    }
  };

  const handleDelete = async (accommodation) => {
    try {
      // Add logic to delete accommodation using axios
      await axios.post(API.AccommodationsCentersDelete, { id: accommodation.id });

      // Update state after successful deletion
      setAccommodationsInfoData((prevData) =>
        prevData.filter((acc) => acc.id !== accommodation.id)
      );

      window.alert("Center deleted successfully!");
    } catch (error) {
      console.error("Error deleting accommodation", error);
    }
  };

  const handleAdd = () => {
    const newAccommodation = {
      id: Date.now().toString(),
      name: "",
      place: "",
    };

    setAccommodationsInfoData((prevData) => [...prevData, newAccommodation]);
    setEditableAccommodationId(newAccommodation.id);
    setShowAddForm(true);
  };

  const handleAddCancel = () => {
    setAccommodationsInfoData((prevData) =>
      prevData.filter((acc) => acc.id !== editableAccommodationId)
    );
    setEditableAccommodationId(null);
    setShowAddForm(false);
  };

  const handleManageCenters = (accommodationCenterId) => {
    navigate("/vcc/accommodation/" + accommodationCenterId);
  };

  const handleAddAccommodation = async () => {
    try {
      const response = await axios.post(
        API.AccommodationsCentersCreate,
        {
          name: editedAccommodation.name,
          place: editedAccommodation.place,
          // Add other fields if needed
        }
      );

      setAccommodationsInfoData((prevData) =>
        prevData.map((acc) =>
          acc.id === editableAccommodationId ? { ...acc, id: response.data.id } : acc
        )
      );

      setShowAddForm(false);
      setEditedAccommodation({});
      window.alert("Accommodation added successfully!");
    } catch (error) {
      console.error("Error adding accommodation", error);
    }
  };

  // Calculate total beds and occupied beds
  const totalBeds = accommodationsInfoData.reduce((acc, accommodation) => acc + (accommodation.total || 0), 0);
  const totalOccupied = accommodationsInfoData.reduce((acc, accommodation) => acc + (accommodation.occupied || 0), 0);


  return (
    <div>
      <h1 className="text-center text-primary">AccommodationsCenters Page</h1>
      <div className="container mt-4">
        <button
          className="btn btn-primary mb-3"
          onClick={handleAdd}
        >
          Add Center
        </button>

        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Place</th>
              <th>Total Bed Count</th>
              <th>Occupied Bed Count</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {showAddForm && (
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Accommodation Name"
                    value={editedAccommodation.name || ""}
                    onChange={(e) =>
                      setEditedAccommodation({ ...editedAccommodation, name: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name of the place"
                    value={editedAccommodation.place || ""}
                    onChange={(e) =>
                      setEditedAccommodation({
                        ...editedAccommodation,
                        place: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <button className="btn btn-success" onClick={handleAddAccommodation}>
                    Add
                  </button>
                  <button className="btn btn-danger ms-2" onClick={handleAddCancel}>
                    Cancel
                  </button>
                </td>
              </tr>
            )}
            {accommodationsInfoData.map((accommodation, index) => (
              <tr key={accommodation.id}>
                <td>{index+1}</td>
                <td>
                  {editableAccommodationId === accommodation.id && !showAddForm ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedAccommodation.place || ""}
                      onChange={(e) =>
                        setEditedAccommodation({ ...editedAccommodation, place: e.target.value })
                      }
                    />
                  ) : (
                    accommodation.place
                  )}
                </td>
                <td>
                {editableAccommodationId === accommodation.id && !showAddForm ? (
                    <input
                      type="text"
                      className="form-control"
                      value={editedAccommodation.total || ""}
                      onChange={(e) =>
                        setEditedAccommodation({ ...editedAccommodation, name: e.target.value })
                      }
                    />
                  ) : (
                    accommodation.total
                  )}
                </td>

                <td>
                {editableAccommodationId === accommodation.id && !showAddForm ? (
                    <></>
                  ) : (
                    accommodation.occupied
                  )}
                </td>
                
                <td>
                  {editableAccommodationId === accommodation.id && !showAddForm ? (
                    <>
                      <button className="btn btn-success" onClick={handleUpdate}>
                        Update
                      </button>
                      <button className="btn btn-danger ms-2" onClick={handleAddCancel}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-info"
                        onClick={() => handleEdit(accommodation)}
                      >
                        <EditOutlined />
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        onClick={() => handleDelete(accommodation)}
                      >
                        <DeleteOutlined />
                      </button>
                      <button
                        className="btn btn-secondary ms-2"
                        onClick={() => handleManageCenters(accommodation.id)}
                      >
                        Manage Places
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2" className="text-end fw-bold">Total:</td>
              <td>{totalBeds}</td>
              <td>{totalOccupied}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default AccommodationsCentersComponent;
