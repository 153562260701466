import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './AddService.css'; // Import your CSS file
import axios from 'axios';
import API from '../../constants/apis';

const EditEventServices = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const { state } = location;
  const { eventId, serviceId } = useParams();
  let serviceInfo;

  useEffect(() => {
    if (state) {
      serviceInfo = state.ServicesRowData;
      if (serviceInfo) {
        setFormData({
          name: serviceInfo.name || '',
          coordinatorName: serviceInfo.coordinator.name || '',
          coordinatorPhone: serviceInfo.coordinator.phone || '',
          spocName: serviceInfo.spoc.name || '',
          spocPhone: serviceInfo.spoc.phone || '',
        });
      }
    }
  }, [state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateService = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        // 'http://localhost:3000/services/update',
        API.ServicesUpdate,
        {
          id: serviceId,
          eventId: eventId,
          name: formData.name,
          coordinator: {
            name: formData.coordinatorName,
            phone: formData.coordinatorPhone,
          },
          spoc: {
            name: formData.spocName,
            phone: formData.spocPhone,
          },
        }
      );
      navigate('/vcc/events/' + eventId);
      window.alert('Service updated successfully!');
      console.log('Service updated successfully', response.data);
    } catch (error) {
      console.error('Error updating service', error);
    }
  };

  return (
    <div className="EditServiceContainer" style={{ maxWidth: '400px', margin: 'auto' }}>
      <div className="EditServiceBox">
        <h1 className="EditServiceHeading">Edit Service</h1>
        <form className="EditServiceForm" onSubmit={updateService}>
          <div className="EditServiceFormGroup">
            <label className="EditServiceLabel">Service Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="EditServiceInput"
            />
          </div>
          <div className="EditServiceFormGroup">
            <label className="EditServiceLabel">Co-Ordinator</label>
            <input
              type="text"
              name="coordinatorName"
              value={formData.coordinatorName}
              onChange={handleInputChange}
              required
              className="EditServiceInput"
            />
          </div>
          <div className="EditServiceFormGroup">
            <label className="EditServiceLabel">Co-Ordinator Phone</label>
            <input
              type="text"
              name="coordinatorPhone"
              value={formData.coordinatorPhone}
              onChange={handleInputChange}
              required
              className="EditServiceInput"
            />
          </div>
          <div className="EditServiceFormGroup">
            <label className="EditServiceLabel">Spoc</label>
            <input
              type="text"
              name="spocName"
              value={formData.spocName}
              onChange={handleInputChange}
              required
              className="EditServiceInput"
            />
          </div>
          <div className="EditServiceFormGroup">
            <label className="EditServiceLabel">Spoc Phone</label>
            <input
              type="text"
              name="spocPhone"
              value={formData.spocPhone}
              onChange={handleInputChange}
              required
              className="EditServiceInput"
            />
          </div>

          <button type="submit" className="EditServiceButton">
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditEventServices;
