const Screens = {
  // LOGOUT_FROM_ALL_DEVICE: 'logoutAllDevice',

  HOME: '/',
  VCCHOME: '/vcc/home',
  ACCOUNT_SETTINGS: 'accountSettings',
  ACCESS_MANAGEMENT: 'accessManagement',
  PROFILE: 'profile',
  CREATE_PROFILE: 'createProfile',
  RESET_PASSWORD: 'resetPassword',

  VCC: 'vcc',

  // Statistics routes
  STATISTICS: 'vcc/statistics',

  // Accommodation routes
  ACCOMMODATION: 'vcc/accommodation',
  CENTERACCOMMODATION: 'vcc/accommodation/:accommodationCenterId',
  // ACCOMMODATION_SETTINGS: 'vcc/accommodation/settings',
  // ADDACCOMMODATIONPlace: 'vcc/accommodation/addplace',
  // EDITACCOMMODATIONPlace: 'vcc/accommodation/edit',

  // Certificates routes
  CERTIFICATES: 'vcc/certificates',

  COORDINATORS: 'coordinators',

  // Events routes
  ADDEVENTS: 'vcc/events/addevents',
  EDITEVENTS: 'vcc/events/edit',
  EVENTS: 'vcc/events',
  EVENTSSERVICE: 'vcc/events/:eventId',
  SERVICE_STATS: 'vcc/events/:eventId/stats',
  SERVICE_FG_STATS: 'vcc/events/:eventId/fg',
  EVENTS_SETTING: 'vcc/events/settings',

  // Service routes
  ADDSERVICES: 'vcc/addservice/:eventId',
  SERVICES: 'vcc/services',
  EDITSERVICES: 'vcc/updateService/:eventId/:serviceId',
  SERVICEVOLUNTEERS: 'vcc/service-volunteers/:eventId/:serviceId',

  // /* UnAuthenticated Links and Routes */
  // ABOUT_US: 'about-us',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGNUP: 'signup',
  PRICING: 'pricing',
  ADVERTISEMENT: 'advertisement',
  FORGOT_PASSWORD: 'forgotPassword',
};

export default Screens;
