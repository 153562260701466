import axios from 'axios';
import Config from '../../config/build';
import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  RESTORE_SESSION,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_OTP_REQUEST,
  REGISTER_OTP_SUCCESS,
  REGISTER_OTP_FAILURE,
  VERIFY_AND_REGISTER_USER_REQUEST,
  VERIFY_AND_REGISTER_USER_SUCCESS,
  VERIFY_AND_REGISTER_USER_FAILURE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  LOGOUT_FROM_ALL_DEVICES_REQUEST,
  LOGOUT_FROM_ALL_DEVICES_SUCCESS,
  LOGOUT_FROM_ALL_DEVICES_FAILURE,
  FORGOT_PASSWORD_OTP_REQUEST,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
} from './userTypes';

export const setNewPasswordRequest = () => {
  return {
    type: SET_NEW_PASSWORD_REQUEST,
  };
};

export const setNewPasswordSuccess = (status) => {
  return {
    type: SET_NEW_PASSWORD_SUCCESS,
    payload: status,
  };
};

export const setNewPasswordFailure = (error) => {
  return {
    type: SET_NEW_PASSWORD_FAILURE,
    payload: error,
  };
};

export const registerOTPRequest = () => {
  return {
    type: REGISTER_OTP_REQUEST,
  };
};

export const registerOTPSuccess = (data) => {
  return {
    type: REGISTER_OTP_SUCCESS,
    payload: data,
  };
};

export const registerOTPFailure = (error) => {
  return {
    type: REGISTER_OTP_FAILURE,
    payload: error,
  };
};

export const forgotPasswordOTPRequest = () => {
  return {
    type: FORGOT_PASSWORD_OTP_REQUEST,
  };
};

export const forgotPasswordOTPSuccess = (data) => {
  return {
    type: FORGOT_PASSWORD_OTP_SUCCESS,
    payload: data,
  };
};

export const forgotPasswordOTPFailure = (error) => {
  return {
    type: FORGOT_PASSWORD_OTP_FAILURE,
    payload: error,
  };
};

export const loginUserRequest = () => {
  return {
    type: LOGIN_USER_REQUEST,
  };
};

export const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};

export const registerUserRequest = () => {
  return {
    type: REGISTER_USER_REQUEST,
  };
};

export const verifyAndRegisterUserRequest = () => {
  return {
    type: VERIFY_AND_REGISTER_USER_REQUEST,
  };
};

export const logoutUserRequest = () => {
  return {
    type: LOGOUT_USER_REQUEST,
  };
};

export const logoutFromAllDeviceRequest = () => {
  return {
    type: LOGOUT_FROM_ALL_DEVICES_REQUEST,
  };
};

const loginUserSuccess = (user) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};

const resetPasswordSuccess = (status) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: status,
  };
};

const registerUserSuccess = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};

const verifyAndRegisterUserSuccess = (user) => {
  return {
    type: VERIFY_AND_REGISTER_USER_SUCCESS,
    payload: user,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

const logoutFromAllDeviceSuccess = () => {
  return {
    type: LOGOUT_FROM_ALL_DEVICES_SUCCESS,
  };
};

const loginUserFailure = (error) => {
  return {
    type: LOGIN_USER_FAILURE,
    payload: error,
  };
};

const resetPasswordFailure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error,
  };
};

const registerUserFailure = (error) => {
  return {
    type: REGISTER_USER_FAILURE,
    payload: error,
  };
};

const verifyAndRegisterUserFailure = (error) => {
  return {
    type: VERIFY_AND_REGISTER_USER_FAILURE,
    payload: error,
  };
};

const logoutUserFailure = (error) => {
  return {
    type: LOGOUT_USER_FAILURE,
    payload: error,
  };
};

const logoutFromAllDeviceFailure = (error) => {
  return {
    type: LOGOUT_FROM_ALL_DEVICES_FAILURE,
    payload: error,
  };
};

const restoreSession = (user) => {
  return {
    type: RESTORE_SESSION,
    payload: user,
  };
};

export const restoreLogin = (user) => {
  return (dispatch) => {
    dispatch(restoreSession(user));
  };
};

const BASE_URL = Config.baseurl;
const LOGIN_USER_URL = BASE_URL + '/auth/login';
const REGISTER_URL = BASE_URL + 'resgister/';
const VERIFY_AND_REGISTER_URL = BASE_URL + 'signup/';
const REGISTER_OTP_URL = BASE_URL + 'signup/otp';
const LOGOUT_URL = BASE_URL + 'logout/';
const RESET_PASSWORD_URL = BASE_URL + 'code/reset';
const LOGOUT_All_Device_URL = BASE_URL + 'logout/allDevices';
const FORGOT_PASSWORD_OTP_URL = BASE_URL + '/forgotPassword/otp';
const SET_NEW_PASSWORD_URL = BASE_URL + '/forgotPassword/reset';

export const forgotPasswordOTP = (customer, marketplaceId) => {
  return (dispatch) => {
    dispatch(forgotPasswordOTPRequest());
    // initiate login Request
    axios({
      method: 'post',
      url: FORGOT_PASSWORD_OTP_URL,
      data: JSON.stringify({
        customer: customer,
        marketplaceId: marketplaceId,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const otpResponse = response.data;
        dispatch(forgotPasswordOTPSuccess(otpResponse));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(forgotPasswordOTPFailure(errorMsg));
      });
  };
};

export const setNewPassword = (
  customer,
  sessionToken,
  otpCode,
  newCode,
  marketplaceId
) => {
  return (dispatch) => {
    dispatch(setNewPasswordRequest());
    // initiate login Request
    axios({
      method: 'post',
      url: SET_NEW_PASSWORD_URL,
      data: JSON.stringify({
        customer: customer,
        sessionToken: sessionToken,
        otpCode: otpCode,
        newCode: newCode,
        marketplaceId: marketplaceId,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const status = response.data;
        dispatch(setNewPasswordSuccess(status));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(setNewPasswordFailure(errorMsg));
      });
  };
};

export const loginUser = (customer, loginCode) => {
  return (dispatch) => {
    dispatch(loginUserRequest());

    // initiate login Request
    axios({
      method: 'post',
      url: LOGIN_USER_URL,
      data: JSON.stringify({
        username: customer,
        loginCode: loginCode,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const user = response.data;
        // console.log(user);
        dispatch(loginUserSuccess(user));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(loginUserFailure(errorMsg));
      });
  };
};

export const registerUser = (
  customer,
  customerName,
  registerCode,
  marketplaceId,
  otp
) => {
  return (dispatch) => {
    dispatch(registerUserRequest());
    // initiate login Request
    axios({
      method: 'post',
      url: REGISTER_URL,
      data: JSON.stringify({
        customer: customer,
        name: customerName,
        registerCode: registerCode,
        marketplaceId: marketplaceId,
        otp: otp,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const user = response.data;
        // console.log(user.customerId);
        dispatch(registerUserSuccess(user));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(registerUserFailure(errorMsg));
      });
  };
};

export const verifyAndRegisterUser = (
  customer,
  customerName,
  registerCode,
  otp,
  sessionToken,
  marketplaceId
) => {
  return (dispatch) => {
    dispatch(verifyAndRegisterUserRequest());
    // initiate login Request
    axios({
      method: 'post',
      url: VERIFY_AND_REGISTER_URL,
      data: JSON.stringify({
        customer: customer,
        name: customerName,
        registerCode: registerCode,
        otpCode: otp,
        sessionToken: sessionToken,
        marketplaceId: marketplaceId,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const user = response.data;
        // console.log(user.customerId);
        dispatch(verifyAndRegisterUserSuccess(user));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(verifyAndRegisterUserFailure(errorMsg));
      });
  };
};

export const registerUserOTP = (customer, marketplaceId) => {
  return (dispatch) => {
    dispatch(registerOTPRequest());
    // initiate login Request
    axios({
      method: 'post',
      url: REGISTER_OTP_URL,
      data: JSON.stringify({
        customer: customer,
        marketplaceId: marketplaceId,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const user = response.data;
        // console.log(user.customerId);
        dispatch(registerOTPSuccess(user));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(registerOTPFailure(errorMsg));
      });
  };
};

export const logoutUser = (customer, accessToken, refreshToken) => {
  return (dispatch) => {
    dispatch(logoutUserRequest());
    // initiate logout Request
    axios({
      method: 'post',
      url: LOGOUT_URL,
      data: JSON.stringify({
        customer: customer,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        Refresh: 'Refresh ' + refreshToken,
      },
    })
      .then((response) => {
        console.log('Logout success: ' + response.data);
        dispatch(logoutUserSuccess());
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log('Logout failure: ' + errorMsg);
        dispatch(logoutUserFailure(errorMsg));
      });
  };
};

export const logoutFromAllDevice = (customer, accessToken, refreshToken) => {
  return (dispatch) => {
    dispatch(logoutFromAllDeviceRequest());
    // initiate logout Request
    axios({
      method: 'post',
      url: LOGOUT_All_Device_URL,
      data: JSON.stringify({
        customer: customer,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        Refresh: 'Refresh ' + refreshToken,
      },
    })
      .then((response) => {
        console.log('Logout success: ' + response.data);
        dispatch(logoutFromAllDeviceSuccess());
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log('Logout failure: ' + errorMsg);
        dispatch(logoutFromAllDeviceFailure(errorMsg));
      });
  };
};

export const resetPassword = (
  customerId,
  accessToken,
  oldCode,
  newCode,
  marketplaceId
) => {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    // initiate Request
    axios({
      method: 'post',
      url: RESET_PASSWORD_URL,
      data: JSON.stringify({
        customerId: customerId,
        oldCode: oldCode,
        newCode: newCode,
        marketplaceId: marketplaceId,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((response) => {
        const status = response.data;
        // console.log(status);
        dispatch(resetPasswordSuccess(status));
      })
      .catch((error) => {
        const errorMsg = error.message;
        // console.log(errorMsg);
        dispatch(resetPasswordFailure(errorMsg));
      });
  };
};
