import React from 'react';
import Screens from '../../constants/Screens';
import './footer.css';

const FooterComponent = (props) => {
  return (
    <div className="footer bgWhite">
      <div className="row align-items-center"></div>
    </div>
  );
};

export default FooterComponent;
