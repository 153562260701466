import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  RESTORE_SESSION,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  REGISTER_OTP_REQUEST,
  REGISTER_OTP_SUCCESS,
  REGISTER_OTP_FAILURE,
  VERIFY_AND_REGISTER_USER_REQUEST,
  VERIFY_AND_REGISTER_USER_SUCCESS,
  VERIFY_AND_REGISTER_USER_FAILURE,
  LOGOUT_FROM_ALL_DEVICES_REQUEST,
  LOGOUT_FROM_ALL_DEVICES_SUCCESS,
  LOGOUT_FROM_ALL_DEVICES_FAILURE,
  FORGOT_PASSWORD_OTP_REQUEST,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
} from './userTypes';

const initialState = {
  loading: false,
  customerId: '',
  connectorId: '',
  customerName: '',
  accessToken: '',
  refreshToken: '',
  otpToken: '',
  newAccountCreated: '',
  newPasswordUpdated: '',
  error: '',
  isLoggedIn: false,
  resetPasswordStatus: '',
  status: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
    case REGISTER_USER_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case LOGOUT_USER_REQUEST:
    case LOGOUT_FROM_ALL_DEVICES_REQUEST:
    case FORGOT_PASSWORD_OTP_REQUEST:
    case SET_NEW_PASSWORD_REQUEST:
    case REGISTER_OTP_REQUEST:
    case VERIFY_AND_REGISTER_USER_REQUEST:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      localStorage.setItem('customerId', action.payload.customerId);
      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      localStorage.setItem('customerName', action.payload.customerName);
      localStorage.setItem('connectorId', action.payload.connectorId);
      return {
        ...state,
        loading: false,
        customerId: action.payload.customerId,
        connectorId: action.payload.connectorId,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        customerName: action.payload.customerName,
        isLoggedIn: true,
        error: '',
      };

    case FORGOT_PASSWORD_OTP_SUCCESS:
    case REGISTER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        customerId: action.payload.customer,
        accessToken: '',
        refreshToken: '',
        customerName: '',
        otpToken: action.payload.details,
        status: action.payload.status,
        isLoggedIn: false,
        error: '',
      };

    case SET_NEW_PASSWORD_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        customerId: '',
        accessToken: '',
        refreshToken: '',
        customerName: '',
        connectorId: '',
        isLoggedIn: false,
        newPasswordUpdated: action.payload,
        error: '',
      };

    case VERIFY_AND_REGISTER_USER_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        customerId: '',
        accessToken: '',
        refreshToken: '',
        customerName: '',
        connectorId: '',
        isLoggedIn: false,
        newPasswordUpdated: '',
        newAccountCreated: action.payload,
        error: '',
      };

    case LOGOUT_USER_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        customerId: '',
        accessToken: '',
        refreshToken: '',
        customerName: '',
        connectorId: '',
        isLoggedIn: false,
        error: '',
      };

    case REGISTER_USER_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        customerId: '',
        accessToken: '',
        refreshToken: '',
        customerName: '',
        connectorId: '',
        error: '',
      };

    case LOGIN_USER_FAILURE:
    case REGISTER_USER_FAILURE:
    case FORGOT_PASSWORD_OTP_FAILURE:
    case REGISTER_OTP_FAILURE:
    case SET_NEW_PASSWORD_FAILURE:
    case VERIFY_AND_REGISTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: true,
      };

    case LOGOUT_FROM_ALL_DEVICES_SUCCESS:
    case LOGOUT_USER_FAILURE:
      localStorage.clear();
      return {
        ...state,
        loading: false,
        customerId: '',
        refreshToken: '',
        accessToken: '',
        customerName: '',
        connectorId: '',
        isLoggedIn: false,
        error: '',
      };

    case LOGOUT_FROM_ALL_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: true,
      };

    case RESTORE_SESSION:
      return {
        ...state,
        loading: false,
        customerId: action.payload.customerId,
        connectorId: action.payload.connectorId,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        customerName: action.payload.customerName,
        isLoggedIn: true,
        error: '',
      };

    default:
      return state;
  }
};

export default userReducer;
