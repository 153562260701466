import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
  Box,
  IconButton,
} from '@mui/material';
import HomeFooter from '../homeFooter/homeFooter';
import happyJanmastami from './happy janmastami.png';
import krishna from './Krishna Janamastami-v2.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import API from '../../constants/apis';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const HomePageNew = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [volunteersData, setVolunteersData] = useState(null);
  const [error, setError] = useState(null);

  const handleCall = () => {
    if (results.spocMobileNumber) {
      window.location.href = `tel:${results.spocMobileNumber}`;
    }
  };

  const callVolunteer = (phoneNumber) => {
    // Implement your logic to initiate a call using the provided phone number
    window.location.href = `tel:${phoneNumber}`;
  };

  const performSearch = async () => {
    try {
      const response = await axios.post(API.SearchVolunteerByPhone, {
        mobileNumber: query.replace(/\s/g, ''),
      });
      // console.log(response)
      if (response.data.length === 0) {
        setResults(null);
        setError('This mobile number not registered services. I request you to provide registered mobile number or kindly contact your folk guide.');
      }
      const serviceName = response.data.service;
      const spocDetailsResponse = await axios.post(API.SearchVolunteerSPOC, {
        name: serviceName,
      });
      setVolunteersData(null);
      if (response.data.mobileNumber === spocDetailsResponse.data.spoc.phone) {
        const volunteersData = await axios.post(
          API.SearchVolunteersByServiceName,
          {
            service: serviceName,
          }
        );
        setVolunteersData(volunteersData.data);
      } else {
        setError(null);
      }

      setResults({
        ...response.data,
        spocName: spocDetailsResponse.data.spoc.name,
        spocMobileNumber: spocDetailsResponse.data.spoc.phone,
      });
      setError(null);
    } catch (error) {
      setResults({});
      setError('This mobile number not registered services. I request you to provide registered mobile number or kindly contact your folk guide.');
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission
    performSearch(); // Call your search function
  };

  useEffect(() => {
    // Disable scrolling by setting overflow: hidden on the body
    document.body.style.overflowX = 'hidden';

    // Cleanup function to re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflowX = 'visible';
    };
  }, []);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          // padding: '20px',
          // backgroundColor: `balck`,
          // background: `url(${bgImg})`,
          minHeight: '96vh',
        }}
      >
        <Grid container alignItems="flex-end">
          <Grid item xs={5} >
            <img src={happyJanmastami} style={{ maxWidth: '300px', minWidth: '50px', width: '100%', height: 'auto', position: 'relative', bottom: 0 }} />
          </Grid>
          <Grid item xs={7}>
            <img src={krishna} style={{ maxWidth: '400px', minWidth: '50px', width: '100%', height: 'auto' }} />
          </Grid>
        </Grid>



        <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          label="Enter 10 digits register mobile number to get service details"
          variant="outlined"
          style={{ width: '60%', marginBottom: '20px' }}
          InputProps={{
            style: {
              borderRadius: '10px', // Curved edges
              color: 'black', // Text color
            },
            notchedOutline: {
              borderColor: '#A01789', // Outline color
            },
          }}
          InputLabelProps={{
            style: { color: '#A01789' },
          }}
        />


          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: '#A01789', color: '#fff', height: '55px', marginLeft: '10px', borderRadius: '10px' }}
          >
            <SearchIcon />
          </Button>
        </form>

        {error ? (
          <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            paddingLeft={3}
            paddingRight={3}
          >
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              xl={4}
              style={{ display: 'flex', justifyContent: 'center' }}
            >

              
            {!error && results && (
              results.fgApproval && results.fgApproval.toLowerCase() === 'approved' ? (
                <Card
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: 'auto',
                    marginTop: '2vh',
                    borderRadius: 16,
                    backgroundColor: '#FEF1FC',
                    position: 'relative',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        color: '#A01789',
                        fontWeight: 'bold',
                        marginBottom: '20px'
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>{results.service}</span>
                      </Box>
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Name:</strong> {results.name || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Folk ID:</strong>{' '}
                      {results.folkId ? results.folkId.toUpperCase() : 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Folk Guide:</strong> {results.folkGuide || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>SPOC:</strong> {results.spocName || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>SPOC Mobile Number:</strong>
                      <IconButton
                        color="primary"
                        onClick={() => window.location.href = `https://wa.me/${results.spocMobileNumber}`}
                        aria-label="WhatsApp"
                        style={{
                          borderRadius: '50%',
                          backgroundColor: '#25D366',
                          color: '#fff',
                          padding: '8px',
                          marginRight: '8px'  // Add margin to the right for spacing
                        }}
                      >
                        <WhatsAppIcon />
                      </IconButton>
                      <span style={{ marginRight: '8px' }}>{results.spocMobileNumber || 'N/A'}</span>
                      <IconButton
                        color="primary"
                        onClick={handleCall}
                        aria-label="Call"
                        style={{
                          borderRadius: '50%',
                          backgroundColor: '#2196f3',
                          color: '#fff',
                          padding: '8px'
                        }}
                      >
                        <PhoneIcon />
                      </IconButton>
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Location:</strong>{' '}
                      {results.serviceLocation || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Attendance:</strong>{' '}
                      {results.attendanceStatus || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Badge Status:</strong>{' '}
                      {results.badgeStatus || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Accommodation:</strong>{' '}
                      {results.accommodation || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Bed Info:</strong>{' '}
                      {results.bedInfo || 'N/A'}
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Service Group:</strong>{' '}
                      {results.serviceGroup ? (
                        <a href={results.serviceGroup} target="_blank" rel="noopener noreferrer">
                          {results.serviceGroup}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <Card
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: 'auto',
                    marginTop: '2vh',
                    borderRadius: 16,
                    backgroundColor: '#FEF1FC',
                    position: 'relative',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        color: '#A01789',
                        fontWeight: 'bold',
                        marginBottom: '20px'
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>Your Service is not approved. Please Contact your folk guide</span>
                      </Box>
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Name:{results.name}</strong>
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>folkid:{results.folkId ? results.folkId.toUpperCase() : 'N/A'}</strong> 
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary" gutterBottom>
                      <strong style={{ color: '#A01789' }}>Folk Guide:{results.folkGuide || 'N/A'}</strong>
                    </Typography>
                  </CardContent>
                </Card>
              )
            )}

            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
        </div>
      </div>

      <HomeFooter />
      <footer
        style={{
          textAlign: 'center',
          padding: '2px',
          backgroundColor: 'black',
        }}
      >
        <p style={{ color: 'white', marginBottom: '5px' }}>© 2023 FolkVCC</p>
      </footer>

    </>
  );
};

export default HomePageNew;
