import React, { useState, useEffect } from 'react';
import { Grid, Card, Typography, TextField, Button, Select, MenuItem, CircularProgress, IconButton,Divider } from '@mui/material';
import axios from 'axios';
import happyJanmastami from './janmastami.jpg';
import krishna from './krishna.jpg';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../../constants/apis';
import { format } from 'date-fns';


const AdminHomePage = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [volunteersData, setVolunteersData] = useState(null);
  const [error, setError] = useState(null);
  const [accommodation, setAccommodation] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedFgApproval, setFgApproval] = useState('');
  const [editedBadgeStatus, setEditedBadgeStatus] = useState('');
  const [editedBadgeCollectedTime, setEditedBadgeColletedTime] = useState('');
  const [editedBadgeReturnedTime, setEditedBadgeReturnedTime] = useState('');
  const [editedAttendance, setEditedAttendance] = useState('');
  const [editedAccommodationInfoId, setEditedAccommodationInfoId] = useState('');
  const [editedAccommodation, setEditedAccommodation] = useState('');
  const [editedBedInfo, setEditedBedInfo] = useState('');

  useEffect(() => {
    fetchAvailableAccommodation();
  }, []);

  const fetchAvailableAccommodation = async () => {
    try {
      const response = await axios.get(API.AvailableAccommodation);
      setAccommodation(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEditClick = (folkId, currentBadgeStatus, currentAccommodation, currentBedInfo, currentAttendance, currentBadgeReturnedTime, currentBadgeColletedTime, FgApproval) => {
    const selectedPlace = accommodation.find(place => place.place === currentAccommodation);
    setEditableRowId(folkId);
    setEditedBadgeStatus(currentBadgeStatus);
    setEditedAccommodation(currentAccommodation);
    setEditedBedInfo(currentBedInfo);
    setEditedAttendance(currentAttendance);
    setEditedBadgeColletedTime(currentBadgeColletedTime);
    setEditedBadgeReturnedTime(currentBadgeReturnedTime);
    setFgApproval(FgApproval)
    if (selectedPlace) {
      setEditedAccommodationInfoId(selectedPlace.id);
    }
  };

  const newHandleUpdateClick = async () => {
    setLoading(true);
    const volunteerModifiedData = {
      id: results.id,
      badgeStatus: editedBadgeStatus,
      attendanceStatus: editedAttendance,
      bedInfo: editedBedInfo,
      accommodation: editedAccommodation,
      accommodationInfoId: editedAccommodationInfoId,
      returnedTime: editedBadgeReturnedTime,
      colletedTime: editedBadgeCollectedTime,
      fgApproval: editedFgApproval
    };
    try {
      const response = await axios.post(API.UpdateVolunteerStatus, volunteerModifiedData);
      setEditableRowId(null);
      await performSearch();
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
    }
  };

  const performSearch = async () => {
    try {
      const response = await axios.post(API.SearchVolunteerByPhone, { mobileNumber: query.replace(/\s/g, '') });

      if (response.data.length === 0) {
        setResults(null);
        setError('No records found');
        return;
      }

      const serviceName = response.data.service;
      const spocDetailsResponse = await axios.post(API.SearchVolunteerSPOC, { name: serviceName });

      setVolunteersData(null);
      if (response.data.mobileNumber === spocDetailsResponse.data.spoc.phone) {
        const volunteersData = await axios.post(API.SearchVolunteersByServiceName, { service: serviceName });
        setVolunteersData(volunteersData.data);
      } else {
        setError(null);
      }

      setResults({
        ...response.data,
        spocName: spocDetailsResponse.data.spoc.name,
        spocMobileNumber: spocDetailsResponse.data.spoc.phone,
      });
      setError(null);
    } catch (error) {
      // console.error('Error fetching data:', error.message);
      setResults({});
      // setError(error.message);
      setError('This mobile number not iregistered for services. I request you to provide registered mobile number or kindly contact your folk guide.');

    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    performSearch();
  };
  
  const handleBadgeReturnedTime = () => {
    const now = new Date();
    const formattedDate = format(now, "yyyy-MM-dd hh:mm a");
    setEditedBadgeReturnedTime(formattedDate);
  };


  const handleBadgeColletedTime = () => {
    const now = new Date();
    const formattedDate = format(now, "yyyy-MM-dd hh:mm a");
    setEditedBadgeColletedTime(formattedDate);
  };


  const handleCancelClick = () => {
    setEditableRowId(null);
  };


  useEffect(() => {
    const now = new Date();
    const formattedDate = format(now, "yyyy-MM-dd hh:mm a");
    setEditedBadgeColletedTime(formattedDate);
  }, []);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          minHeight: '95vh',
          padding: '20px',
          // background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        }}
      >
        <Grid container alignItems="flex-end" style={{marginTop:'-15px'}}>
          <Grid item xs={5}>
            <img
              src={happyJanmastami}
              style={{
                maxWidth: '300px',
                minWidth: '50px',
                width: '100%',
                height: 'auto',
                position: 'relative',
                bottom: 0,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <img
              src={krishna}
              style={{
                maxWidth: '450px',
                minWidth: '50px',
                width: '100%',
                height: 'auto',
              }}
            />
          </Grid>
        </Grid>
  
        <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
          <TextField
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            label="Enter your mobile number to get details"
            variant="outlined"
            style={{
              width: '60%',
              marginBottom: '20px',
              borderRadius: '8px',
              backgroundColor: '#fff',
            }}
          />
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#A01789',
              color: '#fff',
              height: '55px',
              marginLeft: '10px',
              borderRadius: '12px',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
            }}
          >
            <SearchIcon />
          </Button>
        </form>
  
        {loading && <CircularProgress />}
        {error ? (
          <div style={{ color: 'red', marginTop: '2px' }}>{error}</div>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            {results && (
              <Grid item xs={12} sm={6} md={12}>
                <Card
                  onDoubleClick={() =>
                    handleEditClick(
                      results.folkId,
                      results.badgeStatus,
                      results.accommodation,
                      results.bedInfo,
                      results.attendanceStatus,
                      results.returnedTime,
                      results.colletedTime
                    )
                  }
                  elevation={3}
                  style={{
                    padding: '50px',
                    margin: '10px',
                    cursor: 'pointer',
                    width: '40%',
                    textAlign: 'left',
                    margin: 'auto',
                    marginTop: '2vh',
                    borderRadius: 16,
                    backgroundColor: '#FEF1FC',
                    position: 'relative',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.9)',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.02)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                >
                  <Typography variant="h5" style={{ color: '#A01789' }}>
                    <strong></strong> {results.service}
                  </Typography>
                  <Divider />
  
                  <Typography variant="body2" gutterBottom>
                    <strong style={{ color: '#A01789' }}>Name: </strong> {results.name}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Folk Guide:</strong> {results.folkGuide}
                  </Typography>
                  <Divider />
  
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Location:</strong> {results.serviceLocation}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>SPOC Name:</strong> {results.spocName}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>SPOC Contact:</strong> {results.spocMobileNumber}
                  </Typography>

                  <Divider />
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>FG-Approval:</strong> {' '}
                    {editableRowId === results.folkId ? (
                      <Select
                      value={editedFgApproval}
                      onChange={(e) => setFgApproval(e.target.value)}
                      style={{
                        marginTop: '2px',
                        padding: '4px 8px', // Reduce padding for a smaller size
                        fontSize: '14px', // Adjust font size for a smaller appearance
                        height: '20px', // Set a specific height
                        borderRadius: '8px', // Make the corners more rounded
                      }}
                    >
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Disapprove">Disapprove</MenuItem>
                    </Select>
                    
                    ) : (
                      results.fgApproval
                    )}
                  </Typography>
  
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Badge Status:</strong>{' '}
                    {editableRowId === results.folkId ? (
                    //   <Select
                    //   value={editedBadgeStatus}
                    //   onChange={(e) => setEditedBadgeStatus(e.target.value)}
                    //   style={{
                    //     marginTop: '2px',
                    //     padding: '4px 8px', // Reduce padding for a smaller size
                    //     fontSize: '14px', // Adjust font size for a smaller appearance
                    //     height: '20px', // Set a specific height
                    //     borderRadius: '8px', // Make the corners more rounded
                    //   }}
                    // >

                      <Select
                        value={editedBadgeStatus}
                        onChange={(e) => {
                          const badgeValue = e.target.value;
                          setEditedBadgeStatus(badgeValue);
                          const currentTime = format(new Date(), "yyyy-MM-dd hh:mm a");
                          if (badgeValue === 'Returned') {
                            
                            setEditedBadgeReturnedTime(currentTime);
                          } else {
                            setEditedBadgeReturnedTime('');
                          }
                        }}
                        style={{
                          marginTop: '2px',
                          padding: '4px 8px',
                          fontSize: '14px',
                          height: '20px',
                          borderRadius: '8px',
                        }}
                      >



                      
                      <MenuItem value="Not_Collected">Not Collected</MenuItem>
                      <MenuItem value="Collected">Collected</MenuItem>
                      <MenuItem value="Returned">Returned</MenuItem>
                    </Select>
                    
                    ) : (
                      results.badgeStatus
                    )}
                  </Typography>
  
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Accommodation:</strong>{' '}
                    {editableRowId === results.folkId ? (
                      <Select
                        value={editedAccommodation}
                        onChange={(e) => {
                          const selectedPlace = accommodation.find(
                            (place) => place.place === e.target.value
                          );
                          setEditedAccommodationInfoId(selectedPlace.id);
                          setEditedAccommodation(selectedPlace.place);
                        }}
                        // fullWidth
                        style={{
                          marginTop: '2px',
                          padding: '4px 8px', // Reduce padding for a smaller size
                          fontSize: '14px', // Adjust font size for a smaller appearance
                          height: '20px', // Set a specific height
                          borderRadius: '8px', // Make the corners more rounded
                        }}
                      >
                        <MenuItem value="">Select Place</MenuItem>
                        {accommodation.map((place) => (
                          <MenuItem key={place.id} value={place.place}>
                            {place.place}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      results.accommodation
                    )}
                  </Typography>
                  <Divider />
                  
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Bed Info:</strong>{' '}
                    {editableRowId === results.folkId ? (
                      <TextField
                      type="text"
                      value={editedBedInfo}
                      onChange={(e) => setEditedBedInfo(e.target.value)}
                      style={{
                        marginTop: '2px',
                      }}
                      InputProps={{
                        style: {
                          padding: '4px 8px', // Reduce padding for a smaller size
                          fontSize: '14px', // Adjust font size for a smaller appearance
                          height: '20px', // Set a specific height
                          borderRadius: '8px', // Make the corners more rounded
                        },
                      }}
                      inputProps={{
                        style: {
                          padding: '4px 8px', // Further reduce padding for a more compact appearance
                          fontSize: '13px', // Keep the font size consistent
                          height: '15px', // Maintain the height
                          lineHeight: 'normal', // Adjust line height to fit the smaller box
                        },
                      }}
                    />
                    
                    ) : (
                      results.bedInfo
                    )}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Attendance:</strong>{' '}
                    {editableRowId === results.folkId ? (
                      <Select
                        value={editedAttendance}
                        onChange={(e) => {
                          const attendanceValue = e.target.value;
                          setEditedAttendance(attendanceValue);
                          const currentTime = format(new Date(), "yyyy-MM-dd hh:mm a");
                          if (attendanceValue === 'Present') {
                            
                            setEditedBadgeColletedTime(currentTime);
                          } else {
                            setEditedBadgeColletedTime('');
                          }
                        }}
                        style={{
                          marginTop: '2px',
                          padding: '4px 8px',
                          fontSize: '14px',
                          height: '20px',
                          borderRadius: '8px',
                        }}
                      >
                        <MenuItem value="Absent">Absent</MenuItem>
                        <MenuItem value="Present">Present</MenuItem>
                        
                      </Select>
                    ) : (
                      results.attendanceStatus
                    )}
                  </Typography>
                  <Divider />

                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Badge Collected Time:</strong>{' '}
                    {editableRowId === results.folkId ? (
                      <>
                        <TextField
                          type="text"
                          value={editedBadgeCollectedTime || ''}
                          onChange={(e) => setEditedBadgeColletedTime(e.target.value)}
                          placeholder="Badge Collected Time"
                          InputProps={{
                            style: {
                              padding: '4px 8px',
                              fontSize: '14px',
                              height: '25px',
                              borderRadius: '8px',
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: '4px 8px',
                              readOnly: true,
                              fontSize: '13px',
                              height: '15px',
                              lineHeight: 'normal',
                            },
                          }}
                        />
                        {/* <Button
                          onClick={() => {
                            const currentTime = format(new Date(), "yyyy-MM-dd hh:mm a");
                            setEditedBadgeColletedTime(currentTime);
                          }}
                          variant="outlined"
                          style={{
                            marginLeft: '8px',
                            fontSize: '8px',
                            padding: '2px 4px',
                          }}
                        >
                          Set Current Time
                        </Button> */}
                      </>
                    ) : (
                      results.colletedTime || 'Null'
                    )}
                  </Typography>
                  <Divider />


                  <Typography variant="body2">
                    <strong style={{ color: '#A01789' }}>Badge Returned Time:</strong>{' '}
                    {editableRowId === results.folkId ? (
                      <>
                        <TextField
                          type="text"
                          value={editedBadgeReturnedTime || ''}
                          onChange={(e) => setEditedBadgeReturnedTime(e.target.value)}
                          placeholder="Badge Returned Time"
                          InputProps={{
                            style: {
                              padding: '4px 8px', // Reduce padding for a smaller size
                              fontSize: '14px', // Adjust font size for a smaller appearance
                              height: '25px', // Set a specific height
                              borderRadius: '8px', // Make the corners more rounded
                            },
                          }}
                          inputProps={{
                            style: {
                              padding: '4px 8px', // Further reduce padding for a more compact appearance
                              fontSize: '13px', // Keep the font size consistent
                              height: '15px', // Maintain the height
                              lineHeight: 'normal', // Adjust line height to fit the smaller box
                            },
                          }}
                        />
                        {/* <Button
                          onClick={handleBadgeReturnedTime}
                          variant="outlined"
                          style={{
                            marginLeft: '8px',
                            fontSize: '8px',
                            padding: '2px 4px',
                          }}
                        >
                          Set Current Time
                        </Button> */}
                      </>
                    ) : (
                      results.returnedTime || 'Null'
                    )}
                  </Typography>
                  <Divider />


                  {editableRowId === results.folkId && (
                    <div style={{ marginTop: '2px', textAlign: 'right' }}>
                      <IconButton
                        color="primary"
                        onClick={newHandleUpdateClick}
                        style={{
                          marginRight: '10px',
                          backgroundColor: '#A01789',
                          color: '#fff',
                          borderRadius: '50%',
                        }}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={handleCancelClick}
                        style={{
                          backgroundColor: '#f44336',
                          color: '#fff',
                          borderRadius: '50%',
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  )}
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
  
};

export default AdminHomePage;
