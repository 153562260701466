import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import StatsComponent from '../components/stats/stats';
import Site from '../components/site/Site';

export default class StatsPage extends Component {
  render() {
    return (
    <RegularLayout>
      <Site>
        <br />
        <StatsComponent />
      </Site>
      </RegularLayout>
    );
  }
}
