import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import EditEventComponent from '../components/events/EditEvent';
import Site from '../components/site/Site';

export default class EditEventPage extends Component {
  render() {
    return (
      <RegularLayout>
      <Site>
        <br />
        <EditEventComponent />
      </Site>
      </RegularLayout>
    );
  }
}
