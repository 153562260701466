
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Button,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Badge,
  Card,
  CardContent,
  Typography,
  Divider,
  Paper
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SearchIcon from '@mui/icons-material/Search';
import HomeFooter from '../homeFooter/homeFooter';
import happyJanmastami from './happy janmastami.png';
import krishna from './Krishna Janamastami-v2.png';
import API from '../../constants/apis';

const CoordinatorsComponent = () => {
  const [query, setQuery] = useState('');
  const [totalCollected, setTotalCollected] = useState(null);
  const [totalReturned, setTotalReturned] = useState(null);
  const [totalNotCollected, setTotalNotCollected] = useState(null);
  const [results, setResults] = useState(null);
  const [volunteersData, setVolunteersData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  
  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const performSearch = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(API.GetCoordinatorsServices, {
        mobileNumber: query.replace(/\s/g, ''),
      });

      if (response.data.length === 0) {
        setResults(null);
        setError('No records found');
      } else {
        setResults(response.data);
        setVolunteersData(response.data.volunteerList);
        setFilteredData(response.data.volunteerList);
        setTotalCollected(response.data.badgeCollected || 0);
        setTotalReturned(response.data.badgeReturned || 0);
        setTotalNotCollected(response.data.total - response.data.badgeCollected - response.data.badgeReturned || 0);
        setVolunteersData(response.data.volunteerList);
        setFilteredData(response.data.volunteerList);
        setError(null);
      }
    } catch (error) {
      // console.error('Error fetching data:', error.message);
      setError('Enter valid mobile number Spoc Or Co-Ordinator');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    const statusMap = ['all', 'collected','not collected','returned'];
    handleFilter(statusMap[newValue]);
  };

  

  const convertToCSV = (data) => {
    if (!data || data.length === 0) return ''; // Handle empty or undefined data
  
    const serviceName = data[0]?.service || 'Service Name'; // Get the service name from the first row
    const headers = ['Sl. No', 'name', 'mobileNumber', 'badgeStatus', 'status25th', 'status26th'];
    const rows = data.map((row, index) =>
      [index + 1, ...headers.slice(1).map((header) => JSON.stringify(row[header] || ''))].join(',')
    );
    
    // Insert the serviceName as the first row in the CSV
    return ['', `Service Name: ${serviceName}`,  '', '', '', ''].join(',') + '\n' + [headers.join(','), ...rows].join('\n');
  };
  

  const downloadCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      return;
    }
    
    const csv = convertToCSV(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
  
    link.setAttribute('href', url);
    link.setAttribute('download', 'volunteers.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilter = (status) => {
    if (volunteersData) {
      setFilteredData(
        status === 'all'
          ? volunteersData
          : volunteersData.filter(
              (volunteer) => volunteer.badgeStatus?.toLowerCase() === status
            )
      );
    }
  };

  const handleSearchToggle = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    if (volunteersData) {
      setFilteredData(
        volunteersData.filter(
          (volunteer) =>
            volunteer.name?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
            volunteer.service?.toLowerCase().includes(e.target.value?.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = 'visible';
    };
  }, []);

  const badgeStatusColors = {
    collected: 'green',
    returned: 'orange',
    notCollected: 'red',
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'white',
          minHeight: '95vh',
        }}
      >
        <Grid container alignItems="flex-end">
          <Grid item xs={5}>
            <img
              src={happyJanmastami}
              style={{ maxWidth: '250px', minWidth: '50px', width: '100%', height: 'auto', position: 'relative', bottom: -20 }}
              alt="Happy Janmastami"
            />
          </Grid>
          <Grid item xs={7}>
            <img
              src={krishna}
              style={{ maxWidth: '450px', minWidth: '50px', width: '100%', height: 'auto' }}
              alt="Krishna Janmastami"
            />
          </Grid>
        </Grid>

        <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
          <form onSubmit={performSearch}>
            <TextField
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              label="Enter your mobile number"
              variant="outlined"
              style={{ width: '60%', marginBottom: '20px' }}
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              InputLabelProps={{
                style: { color: '#A01789' },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: '#A01789', color: '#fff', height: '55px', marginLeft: '10px', borderRadius: '10px' }}
            >
              <SearchIcon />
            </Button>
          </form>
          Total Volunteers for service: <strong>{results?.total || 0}</strong><br /><button className="btn btn-outline-primary" onClick={downloadCSV}>Download CSV</button>
          
          <div style={{
            display: 'grid',
            gridTemplateColumns: '0fr 0fr',
            gap: '5px',
            backgroundColor: '#f5f5f5',
            padding: '10px',
            minWidth: '0px',
            justifyContent: 'center',
                    alignItems: 'center'
            }}>
                <Button
                  variant={tabIndex === 0 ? "contained" : "outlined"} // Highlights the active button
                  onClick={() => handleTabChange(null, 0)} // Triggers the change
                  style={{
                    backgroundColor: tabIndex === 0 ? '#FFA500' : '#FFA500',
                    color: '#000',
                    borderRadius: '10px',
                    width: '100px', // Fixed width
                    maxWidth: '100px', // Ensure max width remains the same
                    display: 'inline-flex', // Keeps the button inline with other elements
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={results?.total || 0}
                    style={{
                      backgroundColor: '#FFA500',
                      color: '#000',
                      borderRadius: '10px',
                    }}
                  >
                    All 
                  </Badge>
                </Button>


                <Button
                  variant={tabIndex === 1 ? "contained" : "outlined"} // Highlights the active button
                  onClick={() => handleTabChange(null, 1)} // Triggers the change
                  style={{
                    backgroundColor: tabIndex === 1 ? '#c4ca43' : '#c4ca43',
                    color: '#000',
                    borderRadius: '10px',
                    width: '100px', // Fixed width
                    maxWidth: '100px', // Ensure max width remains the same
                    display: 'inline-flex', // Keeps the button inline with other elements
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={totalCollected}
                    style={{
                      backgroundColor: '#c4ca43',
                      color: '#000',
                      borderRadius: '10px',
                    }}
                  >
                    Collected
                  </Badge>
                </Button>

                <Button
                  variant={tabIndex === 2 ? "contained" : "outlined"} // Highlights the active button
                  onClick={() => handleTabChange(null, 2)} // Triggers the change
                  style={{
                    backgroundColor: tabIndex === 2 ? '#f08695' : '#f08695',
                    color: '#000',
                    borderRadius: '10px',
                    width: '150px', // Fixed width
                    maxWidth: '150px', // Ensure max width remains the same
                    display: 'inline-flex', // Keeps the button inline with other elements
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={totalNotCollected}
                    style={{
                      backgroundColor: '#f08695',
                      color: '#000',
                      borderRadius: '10px',
                    }}
                  >
                    Not Collected
                  </Badge>
                </Button>

                <Button
                  variant={tabIndex === 3 ? "contained" : "outlined"} // Highlights the active button
                  onClick={() => handleTabChange(null, 3)} // Triggers the change
                  style={{
                    backgroundColor: tabIndex === 3 ? '#fdde71' : '#fdde71',
                    color: '#000',
                    borderRadius: '10px',
                    width: '100px', // Fixed width
                    maxWidth: '100px', // Ensure max width remains the same
                    display: 'inline-flex', // Keeps the button inline with other elements
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={totalReturned}
                    style={{
                      backgroundColor: '#fdde71',
                      color: '#000',
                      borderRadius: '10px',
                    }}
                  >
                    Returned
                  </Badge>
                </Button>
              </div> 
          </div>
              
        <div style={{ position: 'relative' }}>
          <IconButton onClick={handleSearchToggle} aria-label="Search"
          style={{ position: 'relative' }}
          >
            <SearchIcon />
          </IconButton>

          {searchVisible && (
            <Paper
              style={{
                position: 'right'
              }}
            >
              <TextField
                type="text"
                value={searchText}
                onChange={handleSearchTextChange}
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: '0px',
                  },
                }}
                InputLabelProps={{
                  style: { color: '#A01789' },
                }}
              />
            </Paper>
          )}
        </div>
          

        <div style={{ paddingTop: '20px', maxHeight: '70vh', overflowY: 'auto' }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {!error && filteredData && (
              filteredData.map((volunteer, index) => (
                <Grid item xs={8} sm={6} md={4} lg={4} xl={0} key={index}>
                  <Card style={{ marginBottom: '0px', borderRadius: '8px', boxShadow: '0 2px 4px' }}>
                    <CardContent style={{ display: 'flex', alignItems: 'left', padding: '-2px' }}>
                      <div style={{ display: 'flex-shrink', alignItems: 'left', flex: '1' }}>
                        <div style={{ flex: '1' }}>
                          <Typography variant="h9" style={{ fontWeight: 'bold', marginBottom: '0px' }}>
                            {volunteer.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0px' ,color: '#A01789' }} >
                            <strong>{volunteer.service}</strong>
                          </Typography>
                          <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0px' }}>
                          <span
                              style={{
                                fontSize: '0.75rem',
                                color: badgeStatusColors[volunteer.badgeStatus?.toLowerCase()],
                                marginLeft: '8px',
                                fontWeight: 'normal',
                              }}
                            >
                              <strong>Badge Status: {volunteer.badgeStatus}</strong>
                            </span>
                          </Typography>

                          


                          
                          <Typography
                            variant="body2"
                            style={{
                              marginBottom: '0px',
                              color: volunteer.status25th?.toLowerCase() === 'coming' ? 'darkgreen' : 'red',
                            }}
                          >
                            <strong>25th Status: </strong>{volunteer.status25th || 'N/A'}
                          </Typography>

                          <Typography
                            variant="body2"
                            style={{
                              marginBottom: '0px',
                              color: volunteer.status26th?.toLowerCase() === 'coming' ? 'darkgreen' : 'red',
                            }}
                          >
                            <strong>26th Status:</strong> {volunteer.status26th || 'N/A'}
                          </Typography>


                        </div>
                        <div>
                          {volunteer.mobileNumber === volunteer.whatsappNumber ? (
                            // If mobileNumber and whatsappNumber are the same, render only one set
                            <div>
                              <IconButton
                                color="primary"
                                onClick={() => handleCall(volunteer.mobileNumber)}
                                aria-label="Call"
                              >
                                <CallIcon />
                              </IconButton>
                              <span style={{ marginRight: '8px' }}>{volunteer.mobileNumber || 'N/A'}</span>
                              <IconButton
                                color="primary"
                                onClick={() => window.location.href = `https://wa.me/${volunteer.mobileNumber}`}
                                aria-label="WhatsApp"
                                style={{
                                  borderRadius: '50%',
                                  backgroundColor: '#25D366',
                                  color: '#fff',
                                  padding: '3px'
                                }}
                              >
                                <WhatsAppIcon />
                              </IconButton>
                            </div>
                          ) : (
                            // If mobileNumber and whatsappNumber are different, render both sets
                            <>
                              <div>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleCall(volunteer.mobileNumber)}
                                  aria-label="Call"
                                >
                                  <CallIcon />
                                </IconButton>
                                <span style={{ marginRight: '8px' }}>{volunteer.mobileNumber || 'N/A'}</span>
                                <IconButton
                                  color="primary"
                                  onClick={() => window.location.href = `https://wa.me/${volunteer.mobileNumber}`}
                                  aria-label="WhatsApp"
                                  style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#25D366',
                                    color: '#fff',
                                    padding: '3px'
                                  }}
                                >
                                  <WhatsAppIcon />
                                </IconButton>
                              </div>

                              <div>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleCall(volunteer.whatsappNumber)}
                                  aria-label="Call"
                                >
                                  <CallIcon />
                                </IconButton>
                                <span style={{ marginRight: '8px' }}>{volunteer.whatsappNumber || 'N/A'}</span>
                                <IconButton
                                  color="primary"
                                  onClick={() => window.location.href = `https://wa.me/${volunteer.whatsappNumber}`}
                                  aria-label="WhatsApp"
                                  style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#25D366',
                                    color: '#fff',
                                    padding: '3px'
                                  }}
                                >
                                  <WhatsAppIcon />
                                </IconButton>
                              </div>
                            </>
                          )}
                        </div>


                      </div>
                    </CardContent>
                    <Divider />
                  </Card>
                </Grid>
              ))
            )} {error}
          </Grid>
        </div>
      </div>

      {/* <HomeFooter />
      <footer
        style={{
          textAlign: 'center',
          padding: '0px',
          backgroundColor: 'black',
          position: 'fixed',
          bottom: '0',
          width: '100%',
        }}
      >
        <p style={{ color: 'white', marginBottom: '5px' }}>© 2023 FolkVCC</p>
      </footer> */}
    </>
  );
};

export default CoordinatorsComponent;
