import React from "react";

const CertificatesComponent = () => {
  return (
    <div>
    <h1 className="heading">WelCome to Certificates page.</h1>
    </div>
  );
};

export default CertificatesComponent;
