import React, { Component } from 'react';
import CoodrinatorsComponent from '../components/home/Coordinators-v1';

export default class CoordinatorsHomePage extends Component {
  render() {
    return (
      // <RegularLayout>
        
         <CoodrinatorsComponent />
      
      //  </RegularLayout>
    );
  }
}
