// Action types for CREATE Profile Request
export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAILURE = 'CREATE_PROFILE_FAILURE';

// Action types for GET Profiles Request
export const GET_PROFILES_REQUEST = 'GET_PROFILES_REQUEST';
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const GET_PROFILES_FAILURE = 'GET_PROFILES_FAILURE';

// Action types for Get Rquested Details
export const GET_REQUESTED_DETAILS_REQUEST = 'GET_REQUESTED_DETAILS_REQUEST';
export const GET_REQUESTED_DETAILS_SUCCESS = 'GET_REQUESTED_DETAILS_SUCCESS';
export const GET_REQUESTED_DETAILS_FAILURE = 'GET_REQUESTED_DETAILS_FAILURE';

// Actions for updating currentProfile
export const UPDATE_CURRENT_PROFILE = 'UPDATE_CURRENT_PROFILE';

// GET Tax Type action types
export const GET_TAX_TYPE_REQUEST = 'GET_TAX_TYPE_REQUEST';
export const GET_TAX_TYPE_SUCCESS = 'GET_TAX_TYPE_SUCCESS';
export const GET_TAX_TYPE_FAILURE = 'GET_TAX_TYPE_FAILURE';

export const GET_PROFILE_USERNAME_REQUEST = 'GET_PROFILE_USERNAME_REQUEST';
export const GET_PROFILE_USERNAME_SUCCESS = 'GET_PROFILE_USERNAME_SUCCESS';
export const GET_PROFILE_USERNAME_FAILURE = 'GET_PROFILE_USERNAME_FAILURE';
