export const folkGuides = [
  'Ananta Shayana Prabhu (ATSD)',
  'Angada Prana Prabhu (ANPD)',
  'Hemavarna Gaura Prabhu (HVGD)',
  'Jaya Krishna Prabhu (JYKD)',
  'Karuna Keshava Prabhu (KKSD)',
  'Pavana Nitai Prabhu (PVND)',
  'Rama Sevaka Prabhu (RMSD)',
  'Sri Gopal Prabhu (SGPD)',
  'Sri Vigraha Prabhu (SRID)',
  'Sudharma Krishna Prabhu (SUMD)',
  'Sundara Govinda Prabhu (SUVD)',
  'Jivana Mukta Prabhu (JVMD)',
  'Vishnuduta Prabhu (VSDD)',
  'Vrajeshwara Govinda Prabhu (VGND)',
  'Vijaya Vasudeva Prabhu (VVSD)'
];