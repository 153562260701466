import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Screens from '../constants/Screens';
import VccHomePage from '../screens/VccHomePage';
import FestivalEvents from '../screens/FestivalEvents';
import FestivalEventsSetting from '../screens/FestivalEventsSetting';
import EditEventPage from '../screens/EditEventPage';
import AddEventPage from '../screens/AddeventPage';
import AccommodationPage from '../screens/AccomodationCenterPage';
import CertificatesPage from '../screens/CertificatesPage';
import StatsPage from '../screens/StatsPage';
import ServicePage from '../screens/ServicesPage';
import AddServicePage from '../screens/AddServicePage';
import EditEventServices from '../components/services/EditServices';
import LogoutPage from '../screens/LogoutPage';
import NotFoundPage from '../screens/NotFoundPage';
import ServiceVolunteer from '../screens/ServiceVolunteersPage';

import AccomodationPlacesPage from '../screens/AccommodationPlacesPage';
import ServicesStats from '../components/services/ServicesStats';
import ServicesFgStats from '../components/services/ServicesFgStats';
import CoordinatorsHomePage from '../screens/CoordinatorsHomePage';

const AuthenticatedRouter = () => {
  return (
    <Router>
      <Routes>
        // for events
        <Route path={Screens.COORDINATORS} element={<CoordinatorsHomePage />} />
        <Route path={Screens.EVENTS} element={<FestivalEvents />} />
        <Route
          path={Screens.EVENTS_SETTING}
          element={<FestivalEventsSetting />}
        />
        <Route path={Screens.EDITEVENTS} element={<EditEventPage />} />
        <Route path={Screens.ADDEVENTS} element={<AddEventPage />} />
        <Route path={Screens.LOGIN} element={<VccHomePage />} />
        <Route path={Screens.HOME} element={<VccHomePage />} />
        <Route path={Screens.VCCHOME} element={<VccHomePage />} />
        // for Certificates
        <Route path={Screens.CERTIFICATES} element={<CertificatesPage />} />
        // for Statistics
        <Route path={Screens.STATISTICS} element={<StatsPage />} />
        <Route path={Screens.SERVICE_STATS} element={<ServicesStats />} />
        <Route path={Screens.SERVICE_FG_STATS} element={<ServicesFgStats />} />
        // for services
        <Route path={Screens.EVENTSSERVICE} element={<ServicePage />} />
        <Route path={Screens.ADDSERVICES} element={<AddServicePage />} />
        <Route path={Screens.EDITSERVICES} element={<EditEventServices />} />
        <Route
          path={Screens.SERVICEVOLUNTEERS}
          element={<ServiceVolunteer />}
        />
        // for accommodation
        <Route path={Screens.ACCOMMODATION} element={<AccommodationPage />} />
        <Route
          path={Screens.CENTERACCOMMODATION}
          element={<AccomodationPlacesPage />}
        />
        {/* <Route
          path={Screens.ACCOMMODATION_SETTINGS}
          element={<AccommodationSettingPage />}
        />
        <Route
          path={Screens.ADDACCOMMODATIONPlace}
          element={<AddAccommodationPlace />}
        />
        <Route
          path={Screens.EDITACCOMMODATIONPlace}
          element={<EditAccommodationPalceData />}
        /> */}
        <Route path={Screens.LOGOUT} element={<LogoutPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default AuthenticatedRouter;
