import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import LoginComponent from '../components/login/login';

export default class LoginPage extends Component {
  render() {
    return (
      <RegularLayout>
        <br />
        <LoginComponent />
      </RegularLayout>
    );
  }
}
