import React, { useState } from "react";
import "./Signup.css"; // Import your CSS file

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    folkID: "",
    gender: "Male", // Default gender
    mobileNumber: "",
    folkGuideName: "",
    whatsappNumber: "",
    currentAddress: "",
    stayingInFolkResidency: "Yes", // Default option
    numRoundsChanting: "",
    templeVisits: "",
    currentOccupation: "Working", // Default option
    companyName: "",
    experience: "",
    collegeName: "",
    course: "",
    year: "",
    details: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <h1  className="heading">Create an Account</h1>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Folk ID</label>
            <input type="text" name="folkID" value={formData.folkID} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Gender</label>
            <select name="gender" value={formData.gender} onChange={handleInputChange} required>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="form-group">
            <label>Mobile Number</label>
            <input type="text" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Folk Guide Name</label>
            <input type="text" name="folkGuideName" value={formData.folkGuideName} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>WhatsApp Number</label>
            <input type="text" name="whatsappNumber" value={formData.whatsappNumber} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Current Address</label>
            <input type="text" name="currentAddress" value={formData.currentAddress} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Staying in Folk Residency</label>
            <select name="stayingInFolkResidency" value={formData.stayingInFolkResidency} onChange={handleInputChange} required>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="form-group">
            <label>Number of Rounds Chanting</label>
            <input type="text" name="numRoundsChanting" value={formData.numRoundsChanting} onChange={handleInputChange} required />
          </div>
          {/* <div className="form-group">
            <label>How Often Come to Temple Monthly</label>
            <input type="text" name="templeVisits" value={formData.templeVisits} onChange={handleInputChange} required />
          </div> */}

          <div className="form-group">
            <label>How Often Come to Temple Monthly</label>
            <select name="templeVisits" value={formData.templeVisits} onChange={handleInputChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="More">More</option>
            </select>
          </div>


          <div className="form-group">
            <label>Current Occupation</label>
            <select name="currentOccupation" value={formData.currentOccupation} onChange={handleInputChange} required>
              <option value="Working">Working</option>
              <option value="Student">Student</option>
              <option value="Others">Others</option>
            </select>
          </div>
          {formData.currentOccupation === "Working" && (
            <>
              <div className="form-group">
                <label>Company Name</label>
                <input type="text" name="companyName" value={formData.companyName} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Experience</label>
                <input type="text" name="experience" value={formData.experience} onChange={handleInputChange} required />
              </div>
            </>
          )}
          {formData.currentOccupation === "Student" && (
            <>
              <div className="form-group">
                <label>College Name</label>
                <input type="text" name="collegeName" value={formData.collegeName} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Course</label>
                <input type="text" name="course" value={formData.course} onChange={handleInputChange} required />
              </div>
              <div className="form-group">
                <label>Year</label>
                <input type="text" name="year" value={formData.year} onChange={handleInputChange} required />
              </div>
            </>
          )}
          {formData.currentOccupation === "Others" && (
            <>
              <div className="form-group">
                <label>Details</label>
                <input type="text" name="details" value={formData.details} onChange={handleInputChange} required />
              </div>
            </>
          )}
          
          <button type="submit">Sign Up</button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
