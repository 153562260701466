import Config from '../config/build';
import Screens from '../constants/Screens';

const API = {
  AddServiceVolunteer : `${Config.baseurl}/volunteer/addByService`,
  SearchVolunteerByPhone: `${Config.baseurl}/volunteer/byPhone`,
  SearchVolunteerSPOC: `${Config.baseurl}/volunteer/spoc`,
  SearchVolunteersByServiceName: `${Config.baseurl}/volunteer/byService`,
  UpdateVolunteerStatus: `${Config.baseurl}/volunteer/update`,
  AvailableAccommodation: `${Config.baseurl}/accommodationInfo/available`,
  GetCoordinatorsServices: `${Config.baseurl}/services/byCoordinator`,
  AccommodationsInfo: `${Config.baseurl}/accommodationInfo/`,
  AccommodationsCentersUpdate: `${Config.baseurl}/accommodationCenters/update`,
  AccommodationsCentersCreate: `${Config.baseurl}/accommodationCenters/create`,
  AccommodationsInfoByCenterId: `${Config.baseurl}/accommodationInfo/byCenterId`,
  AccommodationsInfoByUpdate: `${Config.baseurl}/accommodationInfo/update`,
  AccommodationsInfoByCreate: `${Config.baseurl}/accommodationInfo/create`,
  Events: `${Config.baseurl}/events`,
  EventsUpdate: `${Config.baseurl}/events/update`,
  ServicesCreate: `${Config.baseurl}/services/create`,
  ServicesUpdate: `${Config.baseurl}/services/update`,
  ServicesByEventId: `${Config.baseurl}/services/byEventId`,




  



};

export default API;
