import React, { useRef } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../redux';
import Screens from '../../constants/Screens';

const LoginComponent = () => {
  const navigate = useNavigate();

  const customerId = useSelector((state) => state.user.customerId);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isLoading = useSelector((state) => state.user.loading);

  const error = useSelector((state) => state.user.error);

  if (isLoggedIn) {
    navigate('/vcc');
  }

  const dispatch = useDispatch();

  const customerInputRef = useRef();
  const codeInputRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();

    const customer = customerInputRef.current.value;
    const loginCode = codeInputRef.current.value;

    // TODO: Add validation

    // make Login Request
    dispatch(loginUser(customer, loginCode));

    if (isLoggedIn) {
      navigate('/vcc/');
    }
  };

  let isError =
    error !== null &&
    error !== '' &&
    error !== undefined &&
    error !== 'undefined';

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-md-4  tc"></div>
      <div className="col-md-4">
        <div className="row justify-content-center tc">Folk VCC</div>
        <div className="row justify-content-center align-items-center">
          <Card style={{ width: 350 }}>
            <Card.Body>
              <h3 className="text-center mb-4">Login</h3>
              <Form onSubmit={submitHandler}>
                {isError && (
                  <Alert variant="danger" className="mb-3">
                    Invalid credentials!
                  </Alert>
                )}
                <Form.Group controlId="formUsername">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your phone number"
                    maxLength={10}
                    ref={customerInputRef}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    ref={codeInputRef}
                    required
                  />
                </Form.Group>

                <br />
                <div className="d-grid">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                  </Button>
                </div>
              </Form>
              <hr />
              {/* <div className="text-center">
                <p>Are you a new customer?</p>
                <Button variant="link" as={Link} to="/signup">
                  Register
                </Button>
              </div>
              <div className="text-center">
                <p>Did you forget your password?</p>
                <Button
                  variant="link"
                  as={Link}
                  to={`${Screens.HOME}${Screens.FORGOT_PASSWORD}`}
                >
                  Forgot Password
                </Button>
              </div> */}
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="col-md-4"></div>
    </div>
  );
};

export default LoginComponent;
