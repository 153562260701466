import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RegularLayout from '../components/layout/RegularLayout';
import { Card } from 'react-bootstrap';
import { logoutUser } from '../redux';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loading);

  const customerId = useSelector((state) => state.user.customerId);
  const accessToken = useSelector((state) => state.user.accessToken);
  const refreshToken = useSelector((state) => state.user.refreshToken);

  useEffect(() => {
    dispatch(logoutUser(customerId, accessToken, refreshToken));
  }, []);

  if (isLoading) {
    return <h1 className="tc">Logging out</h1>;
  }

  return (
    <RegularLayout>
      <div className="row d-flex justify-content-center tc">
        <br />
        <b>You Successfully Logged Out</b>
        <br />

        <br />
        <div className="row d-flex justify-content-center tc">
          <Card style={{ width: '350px', height: '130px' }}>
            <Card.Body>
              <a
                style={{ margin: '5px' }}
                href="/login"
                className="btn btn-primary"
              >
                Go to Login Page
              </a>
              <a style={{ margin: '5px' }} href="/" className="btn btn-primary">
                Go to Home Page
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </RegularLayout>
  );
};

export default LogoutPage;
