import React from 'react';
import { useSelector } from 'react-redux';
// import './sidenav.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  PoweroffOutlined,
  DashboardOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import Screens from '../../constants/Screens';

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector((state) => state.profile.currentProfile);

  const items = [
    {
      label: 'Home',
      key: Screens.VCCHOME,
      paths: [Screens.VCCHOME],
      icon: <HomeOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
    },
    {
      label: 'Statistics',
      key: Screens.HOME + Screens.STATISTICS,
      paths: [Screens.STATISTICS],
      icon: <DashboardOutlined style={{ fontSize: '20px', color: '#52c41a' }} />,
    },
    {
      label: 'Events',
      key: Screens.HOME + Screens.EVENTS,
      paths: [Screens.EVENTS],
      icon: <DashboardOutlined style={{ fontSize: '20px', color: '#faad14' }} />,
    },
    {
      label: 'Accommodation',
      key: Screens.HOME + Screens.ACCOMMODATION,
      paths: [Screens.ACCOMMODATION],
      icon: <DashboardOutlined style={{ fontSize: '20px', color: '#f5222d' }} />,
    },
    {
      label: 'Certificates',
      key: Screens.HOME + Screens.CERTIFICATES,
      paths: [Screens.CERTIFICATES],
      icon: <DashboardOutlined style={{ fontSize: '20px', color: '#722ed1' }} />,
    },
    {
      label: 'Logout',
      key: '/logout',
      paths: ['logout'],
      icon: <PoweroffOutlined style={{ fontSize: '20px', color: '#f5222d' }} />,
      danger: true,
    },
  ];

  return (
    
      <Menu
        mode="horizontal"
        selectedKeys={items
          .filter((item) =>
            item.paths.includes(location.pathname.split('/')[1])
          )
          .map((item) => item.key)}
        onClick={({ key }) => {
          navigate(key);
        }}
        className="centered-menu"
        style={{ lineHeight: '64px', backgroundColor: '#f5f5f5' ,position: 'center' }}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} icon={item.icon} danger={item.danger}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    
  );
};

export default SideNav;
