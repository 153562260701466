import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import CertificatesComponent from '../components/certificates/certificates';
import Site from '../components/site/Site';

export default class CertificatesPage extends Component {
  render() {
    return (
    <RegularLayout>
      <Site>
        <br />
        <CertificatesComponent />
      </Site>
    </RegularLayout>
    );
  }
}
