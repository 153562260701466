import React, { Fragment } from 'react';
import FooterComponent from '../footer/Footer';
import HeaderComponent from '../header/HeaderComponent';

const RegularLayout = (props) => {
  return (
    <Fragment>
      <HeaderComponent />
      <main style={{ marginBottom: '150px' }}>{props.children}</main>
      <FooterComponent />
    </Fragment>
  );
};

export default RegularLayout;
