import './App.css';

import { useDispatch, useSelector } from 'react-redux';
import AuthenticatedRouter from './routers/AuthenticatedRouter';

import {
  restoreLogin,
  updateCurrentProfile,
  restoreMemberPermissions,
} from './redux';
import UnauthenticatedRouter from './routers/UnauthenticatedRouter';

function App() {
  const dispatch = useDispatch();

  const customerId = localStorage.getItem('customerId');
  const profileId = localStorage.getItem('profileId');

  const validCustomer =
    customerId !== null &&
    customerId !== 'undefined' &&
    customerId !== '' &&
    customerId !== undefined;

  const validProfileId =
    profileId !== null &&
    profileId !== 'undefined' &&
    profileId !== '' &&
    profileId !== undefined;

  if (validCustomer) {
    const user = {
      customerId: customerId,
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      customerName: localStorage.getItem('customerName'),
      connectorId: localStorage.getItem('connectorId'),
    };
    dispatch(restoreLogin(user));
  }

  // if (validProfileId) {
  // const profile = {
  //   profileId: profileId,
  //   type: localStorage.getItem('type'),
  //   name: localStorage.getItem('name'),
  //   designation: localStorage.getItem('designation'),
  // };
  // dispatch(updateCurrentProfile(profile));
  // if (profile.type === 'EMPLOYEE') {
  //   const memberPermissions = localStorage.getItem('memberPermissions');
  //   dispatch(restoreMemberPermissions(memberPermissions));
  // }
  // }

  let isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  if (!isLoggedIn) {
    return <UnauthenticatedRouter />;
  }

  return <AuthenticatedRouter />;
}

export default App;
