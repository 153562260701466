import React, { useState, useEffect, useRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Config from '../../config/build';

const ServicesFgStats = () => {
  const tableRef = useRef();

  const [FgData, setFgStatsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.baseurl + '/volunteer/stats');
        setFgStatsData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const downloadCSV = () => {
    let grandTotalCollected = 0;
    let grandTotalReturned = 0;
    let grandTotalNotCollected = 0;
    let grandTotalTotal = 0;

    const headers = [
      "Folk Guide",
      "Collected",
      "Returned",
      "Yet to Collect",
      "Total",
      "% Render Service"
    ];

    const rows = FgData.map(item => {
      const notCollected = item.total - item.badgeCollected - item.badgeReturned;
      const percentage = (((item.badgeCollected + item.badgeReturned) / item.total) * 100).toFixed(0) + '%';

      grandTotalCollected += item.badgeCollected;
      grandTotalReturned += item.badgeReturned;
      grandTotalNotCollected += notCollected;
      grandTotalTotal += item.total;

      return [
        item.folkGuide,
        item.badgeCollected,
        item.badgeReturned,
        notCollected,
        item.total,
        percentage
      ];
    });

    // Adding the grand total row
    const grandTotalPercentage = (((grandTotalCollected + grandTotalReturned) / grandTotalTotal) * 100).toFixed(0) + '%';
    const grandTotalRow = [
      "Grand Total",
      grandTotalCollected,
      grandTotalReturned,
      grandTotalNotCollected,
      grandTotalTotal,
      grandTotalPercentage
    ];

    rows.push(grandTotalRow);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'fg_stats.csv');
  };

  if (loading) return <div>Loading...</div>;

  const generateTableRows = () => {
    let grandTotalCollected = 0;
    let grandTotalReturned = 0;
    let grandTotalNotCollected = 0;
    let grandTotalTotal = 0;

    const rows = FgData.map((item) => {
      grandTotalCollected += item.badgeCollected;
      grandTotalReturned += item.badgeReturned;
      grandTotalNotCollected += item.total - item.badgeCollected - item.badgeReturned;
      grandTotalTotal += item.total;

      return (
        <tr key={item.folkGuide}>
          <td>{item.folkGuide}</td>
          <td>{item.badgeCollected}</td>
          <td>{item.badgeReturned}</td>
          <td>{item.unattended}</td>
          <td>{item.total}</td>
          <td>{(((item.badgeCollected + item.badgeReturned) / item.total) * 100).toFixed(0)}%</td>
        </tr>
      );
    });

    rows.push(
      <tr key="grandTotal">
        <td style={{ fontWeight: 'bold' }}>Grand Total</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalCollected}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalReturned}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalNotCollected}</td>
        <td style={{ fontWeight: 'bold' }}>{grandTotalTotal}</td>
        <td style={{ fontWeight: 'bold' }}>
          {(((grandTotalCollected + grandTotalReturned) / grandTotalTotal) * 100).toFixed(0)}%
        </td>
      </tr>
    );

    return rows;
  };

  return (
    <div>
      <h1 className="heading" style={{ textAlign: 'center', color: '#004080' }}>
        Folk Guide Stats
      </h1>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '40vh',
        }}
      >
        <div>
          <Button onClick={downloadCSV}>Download as CSV</Button>
          <Table ref={tableRef} striped bordered hover className="tc">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Folk Guide
                </th>
                <th
                  style={{ backgroundColor: '#008cba', color: 'white' }}
                  colSpan="5"
                >
                  Badges
                </th>
              </tr>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}></th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Collected
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Returned
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Yet to Collect
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Total
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  % Render Service
                </th>
              </tr>
            </thead>
            <tbody>{generateTableRows()}</tbody>
          </Table>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ServicesFgStats;
