import React, { Component } from 'react';
import UnaunthenticatedHomePage from '../components/home/unauthenticatedHome';
import HomePageNew from '../components/home/home_new';
export default class HomePage extends Component {
  render() {
    return (
      // <RegularLayout>
       <HomePageNew/>
      //  </RegularLayout>
    );
  }
}
