import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API from '../../constants/apis';
import './AddEvent.css'; // Import your CSS file

const EditEventComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [formData, setFormData] = useState({
    name: (state && state.event && state.event.name) || '',
    startDate: (state && state.event && state.event.startDate) || '',
    endDate: (state && state.event && state.event.endDate) || '',
    place: (state && state.event && state.event.place) || '',
  });

  const eventId = state && state.event && state.event.id;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateEvent = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        // 'http://localhost:3000/events/update',
        API.EventsUpdate,
        {
          id: eventId,
          name: formData.name,
          startDate: formData.startDate,
          endDate: formData.endDate,
          place: formData.place,
          // Add other fields if needed
        }
      );

      navigate('/vcc/events/');
      window.alert('Event updated successfully!');

      // Handle the response as needed, e.g., show a success message
      console.log('Event updated successfully', response.data);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error updating event', error);
    }
  };

  return (
    <div className="AddEventComponent-container">
      <div className="AddEventComponent-box">
        <h1 className="heading">Edit Event</h1>
        <form onSubmit={updateEvent}>
          <div className="form-group">
            <label htmlFor="name">Event Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="startDate">Event Start Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="endDate">Event End Date</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="place">Event Place</label>
            <input
              type="text"
              id="place"
              name="place"
              value={formData.place}
              onChange={handleInputChange}
              required
            />
          </div>

          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  );
};

export default EditEventComponent;
