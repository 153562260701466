import {
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
  MEMBER_PERMISSIONS_REQUEST,
  MEMBER_PERMISSIONS_SUCCESS,
  MEMBER_PERMISSIONS_FAILURE,
} from './accessManagementTypes';

const initialState = {
  loading: false,
  error: '',
  groups: [],
  permissions: [],
  memberPermissions: [],
};

const accountManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
    case CREATE_GROUP_REQUEST:
    case UPDATE_GROUP_REQUEST:
    case DELETE_GROUP_REQUEST:
    case FETCH_PERMISSIONS_REQUEST:
      return { ...state, loading: true, error: '' };

    case MEMBER_PERMISSIONS_REQUEST:
      localStorage.removeItem('memberPermissions');
      return { ...state, loading: true, error: '' };

    case FETCH_GROUPS_SUCCESS:
    case CREATE_GROUP_SUCCESS:
    case DELETE_GROUP_SUCCESS:
    case UPDATE_GROUP_SUCCESS:
      return { ...state, loading: false, groups: action.payload };

    case FETCH_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, permissions: action.payload };

    case MEMBER_PERMISSIONS_SUCCESS:
      localStorage.setItem('memberPermissions', action.payload);
      return { ...state, loading: false, memberPermissions: action.payload };

    case UPDATE_GROUP_FAILURE:
    case DELETE_GROUP_FAILURE:
    case CREATE_GROUP_FAILURE:
    case FETCH_GROUPS_FAILURE:
    case FETCH_PERMISSIONS_FAILURE:
    case MEMBER_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default accountManagementReducer;
