import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Button } from 'react-bootstrap';
import API from '../../constants/apis';
import axios from 'axios';
import Config from '../../config/build';

const ServicesComponent = () => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedServiceName, setEditedServiceName] = useState('');
  const [editedCoordinator, setEditedCoordinator] = useState('');
  const [editedCoordinatorContact, setEditedCoordinatorContact] = useState('');
  const [editedSpocContact, setEditedSpocContact] = useState('');
  const [editedSpocName, setEditedSpocName] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    coordinatorName: '',
    coordinatorPhone: '',
    spocName: '',
    spocPhone: '',
  });

  const navigate = useNavigate();
  const params = useParams();
  const eventId = params.eventId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          Config.baseurl + '/services/byEventId',
          { eventId: eventId }
        );
        setServicesData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  if (loading) return <div>Loading...</div>;

  const handleEdit = (ServicesRowData) => {
    setEditableRowId(ServicesRowData.id);
    setEditedServiceName(ServicesRowData.name);
    setEditedCoordinator(ServicesRowData.coordinator?.name);
    setEditedCoordinatorContact(ServicesRowData.coordinator?.phone);
    setEditedSpocContact(ServicesRowData.spoc?.phone);
    setEditedSpocName(ServicesRowData.spoc?.name);
  };

  const handleUpdateClick = async (ServicesRowData) => {
    try {
      const editedData = {
        id: ServicesRowData.id,
        eventId: eventId,
        name: editedServiceName,
        coordinator: {
          name: editedCoordinator,
          phone: editedCoordinatorContact,
        },
        spoc: {
          name: editedSpocName,
          phone: editedSpocContact,
        },
      };
      const response = await axios.post(
        // 'http://localhost:3000/services/update',
        API.ServicesUpdate,

        editedData
      );

      setEditableRowId(null);
      window.alert('Service updated successfully!');
    } catch (error) {
      console.error('Error updating service', error);
      window.alert('Something went wrong!', error);
    }
  };

  const handleCancelClick = () => {
    setEditableRowId(null);
  };

  const handleDelete = (event) => {
    console.log('Delete event:', event);
  };

  const handleMoreDetails = (service) => {
    navigate('/vcc/service-volunteers/' + eventId + '/' + service.id, {
      state: { service },
    });
  };

  const handleAddService = async () => {
    try {
      const response = await axios.post(
        // 'http://localhost:3000/services/create',
        API.ServicesCreate,
        {
          eventId: eventId,
          name: 'New Service', // Set default values or get values from a form
          coordinator: { name: '', phone: '' },
          spoc: { name: '', phone: '' },
        }
      );

      const newService = response.data;
      setServicesData((prevData) => [newService, ...prevData]);
      setEditableRowId(newService.id);
      window.alert('Service added successfully!');
    } catch (error) {
      console.error('Error adding service', error);
      window.alert('Unable to add service. Please try again.');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        // 'http://localhost:3000/services/create',
        API.ServicesCreate,
        formData
      );

      setServicesData((prevData) => [response.data, ...prevData]);
      setEditableRowId(response.data.id);
      window.alert('Service added successfully!');
    } catch (error) {
      console.error(error);
      window.alert('Unable to add service. Please try again.');
    }
  };

  const updateService = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        // 'http://localhost:3000/services/update',
        API.ServicesUpdate,
        {
          eventId: eventId,
          name: formData.name,
          coordinator: {
            name: formData.coordinatorName,
            phone: formData.coordinatorPhone,
          },
          spoc: {
            name: formData.spocName,
            phone: formData.spocPhone,
          },
        }
      );

      window.alert('Service updated successfully!');
    } catch (error) {
      console.error(error);
      window.alert('Unable to update service. Please try again.');
    }
  };

  const columns = [
    'Service Name',
    'Coordinator',
    'Coordinator Contact',
    'Spoc',
    'Spoc Contact',
    {
      label: 'Actions',
      name: 'actions',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            {editableRowId === servicesData[rowIndex].id ? (
              <>
                <button
                  style={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    padding: '10px',
                    border: 'auto',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleUpdateClick(servicesData[rowIndex])}
                >
                  Update
                </button>
                <button
                  style={{
                    backgroundColor: '#B22222',
                    color: 'white',
                    padding: '10px',
                    border: 'auto',
                    borderRadius: '5px',
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  style={{
                    backgroundColor: '#008CBA',
                    color: 'white',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleEdit(servicesData[rowIndex])}
                >
                  <EditOutlined />
                </button>
                <button
                  style={{
                    backgroundColor: '#FF4500',
                    color: 'white',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleDelete(servicesData[rowIndex])}
                >
                  <DeleteOutlined />
                </button>
                <button
                  style={{
                    backgroundColor: '#808080',
                    color: 'white',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleMoreDetails(servicesData[rowIndex])}
                >
                  More Details
                </button>
              </>
            )}
          </div>
        ),
      },
    },
  ];

  const data = servicesData.map((event) => [
    editableRowId === event.id ? (
      <input
        type="text"
        value={editedServiceName}
        onChange={(e) => setEditedServiceName(e.target.value)}
      />
    ) : (
      event.name
    ),
    editableRowId === event.id ? (
      <input
        type="text"
        value={editedCoordinator}
        onChange={(e) => setEditedCoordinator(e.target.value)}
      />
    ) : event.coordinator ? (
      event.coordinator.name
    ) : (
      'N/A'
    ),
    editableRowId === event.id ? (
      <input
        type="text"
        value={editedCoordinatorContact}
        onChange={(e) => setEditedCoordinatorContact(e.target.value)}
      />
    ) : event.coordinator ? (
      event.coordinator.phone
    ) : (
      'N/A'
    ),
    editableRowId === event.id ? (
      <input
        type="text"
        value={editedSpocName}
        onChange={(e) => setEditedSpocName(e.target.value)}
      />
    ) : event.spoc ? (
      event.spoc.name
    ) : (
      'N/A'
    ),
    editableRowId === event.id ? (
      <input
        type="text"
        value={editedSpocContact}
        onChange={(e) => setEditedSpocContact(e.target.value)}
      />
    ) : event.spoc ? (
      event.spoc.phone
    ) : (
      'N/A'
    ),
    editableRowId === event.id ? (
      <>
        <button
          style={{
            backgroundColor: '#004080',
            color: 'white',
            padding: '5px',
            margin: '2px',
            border: 'none',
            borderRadius: '3px',
          }}
          onClick={() => handleUpdateClick(event)}
        >
          Update
        </button>
        <button
          style={{
            backgroundColor: '#004080',
            color: 'white',
            padding: '5px',
            margin: '2px',
            border: 'none',
            borderRadius: '3px',
          }}
          onClick={handleCancelClick}
        >
          Cancel
        </button>
      </>
    ) : (
      <>
        <button
          style={{
            backgroundColor: '#004080',
            color: 'white',
            padding: '5px',
            margin: '2px',
            border: 'none',
            borderRadius: '3px',
          }}
          onClick={() => handleEdit(event)}
        >
          <EditOutlined />
        </button>
        <button
          style={{
            backgroundColor: '#004080',
            color: 'white',
            padding: '5px',
            margin: '2px',
            border: 'none',
            borderRadius: '3px',
          }}
          onClick={() => handleDelete(event)}
        >
          <DeleteOutlined />
        </button>
        <button
          style={{
            backgroundColor: '#004080',
            color: 'white',
            padding: '5px',
            margin: '2px',
            border: 'none',
            borderRadius: '3px',
          }}
          onClick={() => handleMoreDetails(event)}
        >
          More Details
        </button>
      </>
    ),
  ]);

  const generateTableRows = () => {
    return servicesData.map((item) => (
      <tr key={item.servicesDAO.id}>
        <td>{item.servicesDAO.name}</td>
        <td>{item.servicesDAO.coordinator.name}</td>
        <td>{item.servicesDAO.coordinator.phone}</td>
        <td>{item.servicesDAO.spoc.name}</td>
        <td>{item.servicesDAO.spoc.phone}</td>
        <td>{item.badgeCollected+item.badgeReturned}</td>
        <td>{item.total - item.badgeCollected-item.badgeReturned}</td>
        <td>{item.total}</td>
        <td>{(((item.badgeCollected+item.badgeReturned) / item.total) * 100).toFixed(0)}%</td>
        <td>
          <button
            className="btn btn-primary"
            onClick={() => handleMoreDetails(item.servicesDAO)}
          >
            More Details
          </button>
        </td>
      </tr>
    ));
  };

  // Calculate the totals
  const totalBadgeCollected = servicesData.reduce((sum, item) => sum + item.badgeCollected, 0);
  const totalYetToCollect = servicesData.reduce(
    (sum, item) => sum + (item.total - item.badgeCollected - item.badgeReturned),
    0
  );
  const totalBadges = servicesData.reduce((sum, item) => sum + item.total, 0);
  const totalPercentageCollected = totalBadges ? 
    ((totalBadgeCollected / totalBadges) * 100).toFixed(0) : 0;

  return (
    <div>
      <h1 className="heading" style={{ textAlign: 'center', color: '#004080' }}>
        Welcome to Services Page
      </h1>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '40vh',
        }}
      >
        <div>
          {/* <button
            style={{
              backgroundColor: '#004080',
              color: 'white',
              padding: '10px',
              margin: '10px',
              border: '10px',
              borderRadius: '5px',
            }}
            onClick={handleAddService}
          >
            Add New Service
          </button> */}
          <Button onClick={() => navigate('fg')}>FG's Stats Page</Button> 
          <Button onClick={() => navigate('stats')}>Coordinators Stats Page</Button>
          <Table striped bordered hover className="tc">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Service Name
                </th>
                <th
                  style={{ backgroundColor: '#008cba', color: 'white' }}
                  colSpan="2"
                >
                  Coordinator
                </th>
                <th
                  style={{ backgroundColor: '#008cba', color: 'white' }}
                  colSpan="2"
                >
                  SPOC
                </th>
                <th
                  style={{ backgroundColor: '#008cba', color: 'white' }}
                  colSpan="4"
                >
                  Badges
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}></th>
              </tr>
              <tr>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}></th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Name
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Phone
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Name
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Phone
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Collected
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Yet to Collect
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  Total
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}>
                  % Collected
                </th>
                <th style={{ backgroundColor: '#008cba', color: 'white' }}></th>
              </tr>
            </thead>
            <tbody>{generateTableRows()}</tbody>
            <tfoot>
              <tr>
                <td colSpan="5" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  Totals:
                </td>
                <td>{totalBadgeCollected}</td>
                <td>{totalYetToCollect}</td>
                <td>{totalBadges}</td>
                <td>{totalPercentageCollected}%</td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
          <br/>
          <br/>
          <br/>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ServicesComponent;
