import React from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import AccommodationsCentersComponent from '../components/accommodation/accommodationCenter';
import Site from '../components/site/Site';


function AccomodationPage() {
  return (
    <div>
      <RegularLayout>
      <Site>
        <br />
        <AccommodationsCentersComponent />
      </Site>
      </RegularLayout>
    </div>
  )
}

export default AccomodationPage
