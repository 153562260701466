import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  heading: {
    fontSize: '2em',
    color: '#333',
    marginBottom: '10px',
  },
  message: {
    fontSize: '1em',
    color: '#666',
    marginBottom: '20px',
  },
  link: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
  },
};

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>404 - Not Found</h2>
      <p style={styles.message}>
        The page you are looking for does not exist. Perhaps you mistyped the
        URL or the page has been moved.
      </p>
      <Link to="/" style={styles.link}>
        Go to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
