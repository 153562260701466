import React, { Component } from 'react';
import RegularLayout from '../components/layout/RegularLayout';
import Signup from '../components/signup/Signup';

export default class SignupPage extends Component {
  render() {
    return (
      <RegularLayout>
        <br />
        <Signup />
      </RegularLayout>
    );
  }
}
