import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Config from '../../config/build';

const StatsComponent = () => {
  const [FgData, setFgStatsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.baseurl + '/volunteer/stats');
        setFgStatsData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;

  // Calculate 'not_collected' and 'percentage'
  const formattedData = FgData.map((entry) => ({
    folkGuide: entry.folkGuide,
    collected: entry.badgeCollected,
    total: entry.total,
    not_collected: entry.total - entry.badgeCollected,
    percentage: ((entry.badgeCollected / entry.total) * 100).toFixed(2),
  }));

  return (
    <div>
      <h1 className="heading">Welcome to Statistics page.</h1>
      <h2>Folk Guide wise volunters Statistics</h2>
      <BarChart width={600} height={400} data={formattedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="folkGuide" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="collected" fill="#8884d8" name="Collected" />
        <Bar dataKey="not_collected" fill="#82ca9d" name="Not Collected" />
        <Bar dataKey="total" fill="#ffc658" name="Total" />
        {/* Optional: Uncomment the line below if you want to display the 'percentage' as a line */}
        {/* <Line type="monotone" dataKey="percentage" stroke="#FF0000" yAxisId={0} name="Percentage" /> */}
      </BarChart>
    </div>
  );
};

export default StatsComponent;
