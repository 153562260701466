import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './AddService.css';
import API from '../../constants/apis';
import axios from 'axios';

const AddServiceComponent = () => {
  const { eventId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    coordinator: {
      name: '',
      phone: '',
    },
    spoc: {
      name: '',
      phone: '',
    },
    eventId: eventId,
  });

  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    error: false,
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'coordinator' || name === 'spoc') {
      const subField = name === 'coordinator' ? 'coordinator' : 'spoc';
      const subFieldKey = e.target.getAttribute('data-subfield');
      setFormData({
        ...formData,
        [subField]: {
          ...formData[subField],
          [subFieldKey]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        // 'http://localhost:3000/services/create'
        API.ServicesCreate, formData);
      setSubmitStatus({ success: true, error: false });
      navigate('/vcc/events/' + eventId);
      window.alert('Service added successfully!');
      console.log('Service added successfully', response.data);
    } catch (error) {
      console.error(error);
      setSubmitStatus({ success: false, error: true });
      window.alert('Unable to add service. Please try again.');
    }
  };

  return (
    <div className="add-service-container">
      <div className="add-service-box">
        <h1 className="heading">Create New Service</h1>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label>Service Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Co-Ordinator Name</label>
            <input
              type="text"
              name="coordinator"
              data-subfield="name"
              value={formData.coordinator.name}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Co-Ordinator Phone</label>
            <input
              type="text"
              name="coordinator"
              data-subfield="phone"
              value={formData.coordinator.phone}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Spoc Name</label>
            <input
              type="text"
              name="spoc"
              data-subfield="name"
              value={formData.spoc.name}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Spoc Phone</label>
            <input
              type="text"
              name="spoc"
              data-subfield="phone"
              value={formData.spoc.phone}
              onChange={handleInputChange}
              required
              className="form-input"
            />
          </div>

          <button type="submit" className="form-button">
            Add
          </button>
        </form>
        {submitStatus.success && (
          <div className="success-message">Service added successfully!</div>
        )}
        {submitStatus.error && (
          <div className="error-message">
            Oops! Something went wrong. Please try again.
          </div>
        )}
      </div>
    </div>
  );
};

export default AddServiceComponent;
