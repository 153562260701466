import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Screens from '../constants/Screens';
import LoginPage from '../screens/LoginPage';
import SignupPage from '../screens/SignupPage';
import HomePage from '../screens/HomePage';
import LogoutPage from '../screens/LogoutPage';
import NotFoundPage from '../screens/NotFoundPage';
import CoordinatorsHomePage from '../screens/CoordinatorsHomePage';
import ServicesStats from '../components/services/ServicesStats';
import ServicesFgStats from '../components/services/ServicesFgStats';


const UnauthenticatedRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={Screens.HOME} element={<HomePage />} />
        <Route path={Screens.SIGNUP} element={<SignupPage />} />
        <Route path={Screens.LOGIN} element={<LoginPage />} />
        <Route path={Screens.LOGOUT} element={<LogoutPage />} />
        <Route path={Screens.SERVICE_STATS} element={<ServicesStats />} />
        <Route path={Screens.SERVICE_FG_STATS} element={<ServicesFgStats />} />
        <Route path={Screens.COORDINATORS} element={<CoordinatorsHomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default UnauthenticatedRouter;
