import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Table from 'react-bootstrap/Table';
import API from '../../constants/apis';
import { Card } from 'react-bootstrap';
import { folkGuides } from '../../constants/globalConstants';


const ServiceVolunteer = () => {
  const location = useLocation();
  const { eventId, serviceId } = useParams();
  const { service } = location.state || {};

   // New state for the form
   const [newVolunteer, setNewVolunteer] = useState({
    service:service.name,
    name: '',
    mobileNumber: '',
    whatsappNumber: '',
    folkGuide: '',
    folkId: '',
    status25th: 'Not Coming',
    status26th: 'Coming',
  });

  // Handlers for the new volunteer form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVolunteer((prevData) => ({ ...prevData, [name]: value, [service]:service.name }));
  };

  const handleAddVolunteer = async () => {
    try {
  
      // Make the POST request to the API
      const response = await axios.post(API.AddServiceVolunteer, newVolunteer);
  
      // Check the response status or handle the response data as needed
      if (response.status === 200) {
        alert('Volunteer added successfully');
  
        // Optionally, update the serviceVolunteersData state with the new volunteer
        setServiceVolunteersData((prevData) => [...prevData, response.data]);
  
        // Hide the form and reset the input fields
        setShowAddForm(false);
        setNewVolunteer({
          service: '',
          name: '',
          mobileNumber: '',
          whatsappNumber: '',
          folkGuide: '',
          folkId: '',
          status25th: '',
          status26th: '',
        });
      } else {
        alert('Failed to add volunteer:', response);
      }
    } catch (error) {
      alert('Error adding volunteer:', error);
    }
  };

  const handleCancelAdd = () => {
    setShowAddForm(false);
    setNewVolunteer({
      name: '',
      mobileNumber: '',
      whatsappNumber: '',
      folkGuide: '',
      folkId: '',
      status25th: 'Not Coming',
      status26th: 'Coming',
    });
  };

  const [showAddForm, setShowAddForm] = useState(false);
  const [serviceVolunteersData, setServiceVolunteersData] = useState([]);
  const [accommodation, setAccommodation] = useState([]);
  const [volunteersStatus, setVolunteersStatus] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);
  // get Updated when drop down gets updated
  const [editedAccommodationInfoId, setEditedAccommodationInfoId] =
    useState('');
  const [editedAccommodation, setEditedAccommodation] = useState('');
  // Gets updated from input box
  const [editedBedInfo, setEditedBedInfo] = useState('');
  const [editedBadgeStatus, setEditedBadgeStatus] = useState('');
  const [editedAttendance, setEditedAttendance] = useState('');

  useEffect(() => {
    fetchVolunteers();
    fetchAvailableAccommodation();
    // fetchVolunteersStatus();
  }, [service]);

  const fetchAvailableAccommodation = async () => {
    try {
      const response = await axios.get(
        // 'http://localhost:3000/accommodationInfo/available'
        API.AvailableAccommodation
      );
      setAccommodation(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchVolunteers = async () => {
    try {
      const response = await axios.post(
        // 'http://localhost:3000/volunteer/byService',
        API.SearchVolunteersByServiceName,
        {
          service: service.name,
        }
      );
      setServiceVolunteersData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEditClick = (
    folkId,
    currentBadgeStatus,
    currentAccommodation,
    currentAttendance
  ) => {
    setEditableRowId(folkId);
    setEditedBadgeStatus(currentBadgeStatus);
    setEditedAccommodation(currentAccommodation);
    setEditedAttendance(currentAttendance);
  };

  const newHandleUpdateClick = async (folkId) => {
    const updatedRowData = serviceVolunteersData.find(
      (volunteerData) => volunteerData.folkId === folkId
    );

   

    const volunteerModifiedData = {
      id: updatedRowData.id,
      badgeStatus: editedBadgeStatus,
      attendanceStatus: editedAttendance,
      bedInfo: editedBedInfo,
      accommodation: editedAccommodation,
      accommodationInfoId: editedAccommodationInfoId,
    };
    const response = await axios.post(
      API.UpdateVolunteerStatus,
      volunteerModifiedData
    );
    const updatedVolunteerData = response.data;

    // Update the serviceVolunteersData state
    setServiceVolunteersData((prevData) => {
      const updatedData = prevData.map((volunteer) =>
        volunteer.folkId === folkId ? updatedVolunteerData : volunteer
      );

      return updatedData;
    });

    setEditableRowId(null);
  };

  const handleCancelClick = () => {
    setEditableRowId(null);
  };

  return (
    <div style={{ padding: '20px', background: '#f4f4f4', minHeight: '100vh' }}>
      <h1 className="heading" style={{ textAlign: 'center', color: '#B22222' }}>
        {service.name} Service Volunteers
      </h1>
      {!showAddForm &&
      <button className="btn btn-primary" onClick={() => setShowAddForm(true)}>
        Add On-Spot Volunteer
      </button>}
      {showAddForm && (
        <Card style={{ width: '500px' }}>
        <div style={{ margin: '20px 0', }}>
          <h3>Add On-Spot Volunteer</h3>
          <form>
          <div>
              <label>Service: </label>
              <input
              disabled
                type="text"
                name="service"
                value={service.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Name: </label>
              <input
                type="text"
                name="name"
                value={newVolunteer.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Mobile Number: </label>
              <input
                type="text"
                name="mobileNumber"
                value={newVolunteer.mobileNumber}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Whatsapp Number: </label>
              <input
                type="text"
                name="whatsappNumber"
                value={newVolunteer.whatsappNumber}
                onChange={handleInputChange}
              />
            </div>
            <label>
              Folk Guide:
              <select
                value={newVolunteer.folkGuide}
                onChange={(e) => setNewVolunteer({ ...newVolunteer, folkGuide: e.target.value })}
              >
                <option value="">Select Folk Guide</option>
                {folkGuides.map((guide) => (
                  <option key={guide} value={guide}>
                    {guide}
                  </option>
                ))}
              </select>
            </label>
            <div>
              <label>Folk ID: </label>
              <input
                type="text"
                name="folkId"
                value={newVolunteer.folkId}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Status 25th: </label>
              <select
                name="status25th"
                value={newVolunteer.status25th}
                onChange={handleInputChange}
              >
                <option value="Not Coming">Not Coming</option>
                <option value="Coming">Coming</option>
              </select>
            </div>
            <div>
              <label>Status 26th: </label>
              <select
                name="status26th"
                value={newVolunteer.status26th}
                onChange={handleInputChange}
              >
                <option value="Coming">Coming</option>
                <option value="Not Coming">Not Coming</option>
              </select>
            </div>
            <button type="button" onClick={handleAddVolunteer} className="btn btn-success">
              Submit
            </button>
            <button type="button" onClick={handleCancelAdd} className="btn btn-danger">
              Cancel
            </button>
          </form>
        </div>
        </Card>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '40vh',
        }}
      >
        <div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Folk Guide</th>
                <th>Service</th>
                <th>Badge Status</th>
                <th>Accommodation</th>
                <th>Bed Info</th>
                <th>Attendance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {serviceVolunteersData.map((volunteerData) => (
                <tr key={volunteerData.folkId}>
                  <td>{volunteerData.name}</td>
                  <td>{volunteerData.mobileNumber}</td>
                  <td>{volunteerData.folkGuide}</td>
                  <td>{volunteerData.service}</td>
                  <td>
                    {editableRowId === volunteerData.folkId ? (
                      <select
                        value={editedBadgeStatus}
                        onChange={(e) => setEditedBadgeStatus(e.target.value)}
                      >
                        <option value="Not_Collected">Not Collected</option>
                        <option value="Collected">Collected</option>
                        <option value="Returned">Returned</option>
                      </select>
                    ) : (
                      volunteerData.badgeStatus
                    )}
                  </td>
                  <td>
                    {editableRowId === volunteerData.folkId ? (
                      <select
                        onChange={(e) => {
                          const selectedPlace = accommodation.find(
                            (place) => place.id === e.target.value
                          );
                          setEditedAccommodationInfoId(selectedPlace.id);
                          setEditedAccommodation(selectedPlace.place);
                        }}
                      >
                        <option value="">Select Place</option>
                        {accommodation.map((place) => (
                          <option key={place.id} value={place.id}>
                            {place.place}
                          </option>
                        ))}
                      </select>
                    ) : (
                      volunteerData.accommodation
                    )}
                  </td>
                  <td>
                    {editableRowId === volunteerData.folkId ? (
                      <input
                        type="text"
                        value={editedBedInfo}
                        onChange={(e) => setEditedBedInfo(e.target.value)}
                      />
                    ) : (
                      volunteerData.bedInfo
                    )}
                  </td>
                  <td>
                    {editableRowId === volunteerData.folkId ? (
                      <select
                        onChange={(e) => setEditedAttendance(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Absent">Absent</option>
                        <option value="Present">Present</option>
                      </select>
                    ) : (
                      volunteerData.attendanceStatus
                    )}
                  </td>

                  <td>
                    {editableRowId === volunteerData.folkId ? (
                      <>
                        <button
                          onClick={() =>
                            newHandleUpdateClick(volunteerData.folkId)
                          }
                          className="btn btn-success"
                        >
                          Update
                        </button>
                        <button
                          onClick={handleCancelClick}
                          className="btn btn-danger"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          handleEditClick(
                            volunteerData.folkId,
                            volunteerData.badgeStatus,
                            volunteerData.accommodation,
                            volunteerData.attendenceStatus
                          )
                        }
                        className="btn btn-primary"
                      >
                        <EditIcon />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ServiceVolunteer;
